<template>
  <div
    class="event-card"
    :class="{ 'clickable-card': isClickable }"
    @click="openEventPage"
  >
    <div class="event-badge" v-if="event.rescheduled">
      <img :src="require('@/assets/calendar_icon.svg')" class="badge-icon" />
      <span class="badge-text">{{ $t("new_date") }}</span>
    </div>
    <div class="event-card-date" :style="{ 'background-image': `url(${pic})` }">
      <div class="bg-card-shadow">
        <div class="mem-font--event-card__month card-mounth">
          {{ eventMounth }}
        </div>
        <div class="mem-font--event-card__day">{{ eventDay }}</div>
        <div v-if="teamEvent" class="card-time">
          <div class="commit-button">
            <mem-button
              v-if="!event.has_ticket"
              :btnType="'mem-primary'"
              @click="commitEvent"
              >{{ $t("commit") }}</mem-button
            >
            <div v-if="event.has_ticket" class="has-ticket">
              <i class="material-icons"> done </i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- event list card -->
    <div class="event-card-info" v-if="!teamEvent">
      <div class="event-card-title">
        <div class="title-wrap">{{ event.name }}</div>
      </div>
      <div class="event-card-layout">
        <div class="event-card-teams">
          <div class="event-card-team cut-text" v-if="eventTeam">
            <span class="team-name">{{ eventTeam.name }}</span>
            <div class="team-members">
              <div
                class="team-member"
                v-for="(member, index) in eventTeam.members"
                v-bind:key="index"
                :class="[`member-pos-${index + 1}`]"
              >
                <div
                  class="member-avatar"
                  :style="{ background: getColor() }"
                  v-if="index <= 2"
                >
                  <div v-if="!member.photo">
                    {{ getAvatarLetters(member.name) }}
                  </div>
                  <div v-if="member.photo" class="member-photo">
                    <img :src="member.photo" alt="ph" />
                  </div>
                </div>
              </div>
              <div class="team-count" v-if="eventTeam.members.length > 3">
                +{{ eventTeam.members.length - 3 }}
              </div>
            </div>
          </div>
        </div>
        <div class="event-tickets">
          <div v-if="unassignedTicketsCount && unassignedTicketsCount === 1">
            {{ "1 " + $t("unassigned_ticket") }}
          </div>
          <div v-if="unassignedTicketsCount && unassignedTicketsCount > 1">
            {{ unassignedTicketsCount + " " + $t("unassigned_tickets") }}
          </div>
        </div>
      </div>
    </div>
    <!-- team event card -->
    <div class="event-card-info team-event-info" v-if="teamEvent">
      <div class="event-card-title">
        <div class="title-wrap">
          {{ event.name }}
        </div>
      </div>
      <div class="team-event-body">
        <div class="members-info">
          <div class="event-card-teams">
            <div class="event-card-team">
              <div class="team-members" v-if="event.members">
                <div
                  class="team-member"
                  v-for="(member, index) in eventMembers"
                  v-bind:key="index"
                  :class="[`member-pos-${index + 1}`]"
                >
                  <div
                    class="member-avatar"
                    :style="{ background: getColor() }"
                    v-if="index <= 2"
                  >
                    <div v-if="!member.photo">
                      {{ getAvatarLetters(member.name) }}
                    </div>
                    <div v-if="member.photo" class="member-photo">
                      <img :src="member.photo" alt="ph" />
                    </div>
                  </div>
                </div>
                <div class="team-count" v-if="event.members_count > 3">
                  +{{ event.members_count - 3 }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-buttons">
          <template>
            <div @click.stop="showSchedule" class="schedule-button">
              <i class="material-icons"> watch_later </i>
              <span>{{ $t("schedule") }}</span>
            </div>
            <v-dialog
              v-model="scheduleModal"
              overlay-color="#000000"
              overlay-opacity="0.54"
              :max-width="360"
              class="mem-dialog"
            >
              <schedule-dialog
                v-if="scheduleModal"
                :teamId="teamId"
                :event="event"
                v-on:close-schedule="scheduleModal = false"
              ></schedule-dialog>
            </v-dialog>
          </template>
          <div class="commit-button">
            <mem-button
              v-if="!event.has_ticket"
              :btnType="'mem-primary'"
              @click="commitEvent"
              >{{ $t("commit") }}</mem-button
            >
            <div v-if="event.has_ticket" class="has-ticket">
              <i class="material-icons"> done </i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import Button from "./base/BaseButton.vue";
import EventScheduleDialog from "../views/Teams/components/EventScheduleDialog.vue";
export default {
  data: () => ({
    colors: [
      "#F4741D",
      "#6DA286",
      "#8F34C1",
      "#2361A7",
      "#84BD00",
      "#CF1019",
      "#FFB800",
    ],
    scheduleModal: false,
  }),
  props: {
    event: {
      type: Object,
      required: true,
    },
    startDate: String,
    endDate: String,
    pic: String,
    teamEvent: {
      type: Boolean,
      default: false,
    },
    teamId: [String, Number],
    isClickable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(["unassignedTickets", "specificLinks"]),
    eventDay() {
      return moment(this.startDate).format("DD");
    },
    eventMounth() {
      return moment(this.startDate).format("MMM");
    },
    eventTeam() {
      return this.event.teams[0];
    },
    isDisabled() {
      return (
        moment().format("YYYY-MM-DD") >=
          moment(this.startDate).format("YYYY-MM-DD") &&
        moment().format("YYYY-MM-DD") <=
          moment(this.endDate).format("YYYY-MM-DD")
      );
    },
    unassignedTicketsCount() {
      // let groupTickets = groupBy(this.unassignedTickets);
      // return groupTickets[this.event.id]?.length;
      return this.event.unassigned_tickets_count;
    },
    eventMembers() {
      if (this.event.members && this.event.members.length > 3)
        return this.event.members.slice(0, 3);
      return this.event.members;
    },
  },
  components: {
    "mem-button": Button,
    "schedule-dialog": EventScheduleDialog,
  },
  methods: {
    getColor() {
      return this.colors[Math.floor(Math.random() * this.colors.length)];
    },
    getAvatarLetters(name) {
      let fullName = name.split(" ");
      return fullName[0].charAt(0) + fullName[1].charAt(0);
    },
    openEventPage() {
      if (!this.isClickable) return;
      else
        this.$router.push({
          name: "eventPage",
          params: { id: this.event.id },
        });
    },
    commitEvent() {
      let windowReference = window.open();
      windowReference.location = `${this.specificLinks.authPage}/race/detail/${this.event.race_id}/overview`;
    },
    showSchedule() {
      console.log("open Schedule");
      this.scheduleModal = true;
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
@mixin hasTicketStatus($size) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #5b5b5b;
  border-radius: 100px;
  height: $size;
  width: $size;
}
.event-card {
  display: grid;
  grid-template-columns: 136px 1fr;
  height: 136px;
  background: #121212;
  max-width: 670px;
  margin-bottom: 16px;
  position: relative;

  .event-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 28px;
    background: #cf1019;
    width: 136px;
    z-index: 10;

    .badge-icon {
      height: 14px;
      width: 14px;
      filter: brightness(0) invert(1);

      & + .badge-text {
        margin-left: 9px;

        @media screen and (max-width: 499px) {
          margin-left: 0;
        }
      }
    }
    .badge-text {
      text-transform: uppercase;
      color: white;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
    }
  }
  .event-card-date {
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-position-y: 50%;
    position: relative;
    // background-repeat: no-repeat;
    .bg-card-shadow {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(000, 000, 000, 0.5);
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .card-mounth {
        margin-bottom: 2px;
      }
      .card-time {
        margin-top: 12px;
        display: none;
        @media screen and (max-width: 899px) {
          display: block;
        }
        .commit-button {
          button {
            height: 32px;
            padding: 0 18px;
            font-size: 10px;
            line-height: 12px;
            font-weight: bold;
          }
          .has-ticket {
            i {
              font-size: 26px;
            }
            @include hasTicketStatus(37px);
          }
        }
        // .time-title {
        //   font-size: 10px;
        //   font-weight: 600;
        //   text-transform: uppercase;
        //   line-height: 12px;
        //   margin-bottom: 2px;
        // }
        // .time-value {
        //   font-size: 14px;
        //   line-height: 17px;
        //   font-weight: 600;
        // }
      }
    }
  }

  .event-card-info {
    padding: 24px 30px 20px 24px;
    display: flex;
    flex-direction: column;
    .event-card-title {
      font-size: 20px;
      line-height: 24px;
      font-weight: bold;
      margin-bottom: 18px;
      display: flex;
      .title-wrap {
        overflow: hidden;
        // text-overflow: ellipsis;
        // -webkit-box already includes text-overflow: ellipsis
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        @media screen and (max-width: 499px) {
          -webkit-line-clamp: 2;
        }
      }
    }
    .event-card-layout {
      display: grid;
      grid-template-columns: 1fr 1fr;
      @media screen and (max-width: 499px) {
        grid-template-columns: 1fr 6rem;
      }
      .event-tickets {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        font-size: 12px;
        font-weight: 600;
        line-height: 17px;
        text-transform: uppercase;
        color: #ef4969;
        padding-top: 20px;
        @media screen and (max-width: 499px) {
          padding-top: 0;
        }
      }
    }
    .event-card-teams {
      display: flex;
      flex-direction: row;
      .event-card-team {
        width: 86px;
        margin-right: 12px;
        .team-name {
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 0.05em;
          text-transform: uppercase;
        }
        .team-members {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 2px;
          position: relative;
          height: 24px;
          .team-member {
            position: absolute;
            font-family: "Druk Cond", "Roboto", sans-serif;
            width: 28px;
            height: 28px;
            background: #121212;
            top: -2px;
            border-radius: 50%;
            display: flex;
            flex-direction: row;
            align-items: center;
            .member-avatar {
              height: 24px;
              width: 24px;
              border-radius: 50%;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              .member-photo {
                height: 24px;
                width: 24px;
                img {
                  height: 24px;
                  width: 24px;
                  border-radius: 50%;
                }
              }
            }
          }
          .team-count {
            position: absolute;
            // right: 12px;
            margin-left: 58px;

            font-weight: bold;
            font-size: 12px;
            line-height: 15px;

            text-align: right;
            letter-spacing: 0.05em;
            text-transform: uppercase;
          }
          .member-pos-1 {
            left: 0;
            z-index: 3;
          }
          .member-pos-2 {
            left: 13px;
            z-index: 2;
          }
          .member-pos-3 {
            left: 26px;
            z-index: 1;
          }
        }
      }
      // .team-event-section {
      //   display: flex;
      //   flex-direction: row;
      //   flex-grow: 100;
      //   justify-content: space-between;
      //   @media screen and (max-width: 499px) {
      //     justify-content: flex-end;
      //   }
      // .event-time {
      //   margin-left: 38px;
      //   @media screen and (max-width: 499px) {
      //     display: none;
      //   }
      //   .time-title {
      //     font-size: 12px;
      //     line-height: 15px;
      //     text-transform: uppercase;
      //     font-weight: 600;
      //     letter-spacing: 0.05em;
      //   }
      //   .time-value {
      //     margin-top: 6px;
      //     font-size: 16px;
      //     line-height: 20px;
      //     font-weight: 600;
      //   }
      // }
      // }
    }
  }
  .vr-event-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 45px;
    .submit-button {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;

      padding-bottom: 8px;

      button:disabled {
        // border: 1px solid rgba(255, 255, 255, 0.21);
        background: #4f4f4f;
        // color: rgba(255, 255, 255, 0.2);
        cursor: default !important;
      }

      button:disabled:hover {
        opacity: 1;
      }

      .btn-tertiary-dark {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 12px;
        padding: 0 20px 0 13px;
        letter-spacing: 0.04em;
        i {
          margin-right: 7px;
          font-size: 20px;
        }
      }
    }
  }
  .team-event-info {
    @media screen and (max-width: 899px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 35px;
    }
    .team-event-body {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 3px;

      .members-info {
        display: flex;
        flex-direction: row;
        @media screen and (max-width: 899px) {
        }
        .event-card-teams {
          .event-card-team {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 0;
          }
        }
      }
      .card-buttons {
        height: 44px;
        display: flex;
        flex-direction: row;
        @media screen and (max-width: 899px) {
          height: 32px;
        }
        .schedule-button {
          display: flex;
          flex-direction: row;
          align-items: center;
          border: 1px solid #262626;
          border-radius: 30px;
          padding: 0 27px;
          font-size: 14px;
          line-height: 17px;
          font-weight: bold;
          text-transform: uppercase;
          margin-right: 12px;
          @include cursorPointer;
          // white-space: nowrap;
          @media screen and (max-width: 899px) {
            font-size: 10px;
            line-height: 12px;
            padding: 0 10px;
          }
          i {
            font-size: 18px;
            margin-right: 8px;
          }
        }
        .schedule-button:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
        .commit-button {
          @media screen and (max-width: 899px) {
            display: none;
          }
          .has-ticket {
            i {
              font-size: 28px;
            }
            @include hasTicketStatus(43px);
          }
        }
      }
    }
  }
}
// .event-card:hover {
//   transform: scale(1.03);
// }
.clickable-card {
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.5s;
  @include cursorPointer;
}
.clickable-card:hover {
  transform: scale(1.03);
}
.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
@media screen and (max-width: 499px) {
  .event-card {
    grid-template-columns: 106px 1fr;
    height: 156px;
    margin-bottom: 12px;

    .event-badge {
      width: 106px;

      .badge-icon {
        display: none;
      }
    }
    .event-card-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .event-card-title {
        font-size: 18px;
        line-height: 22px;
      }
    }
    .vr-event-info {
      padding-right: 24px;
      .submit-button {
        padding-bottom: 0;
      }
    }
  }
}
</style>
