<template>
  <div class="confirm-dialog">
    <div class="close-dialog">
      <i class="material-icons" @click="closeModal"> close </i>
    </div>
    <div class="dialog-image">
      <div
        v-if="!member.avatar"
        class="default-avatar"
        :style="{ 'background-color': defaultAvatarColor }"
      >
        <div>{{ getAvatarLetters }}</div>
      </div>
      <img v-if="member.avatar" :src="member.avatar" alt="pic" />
    </div>
    <div class="dialog-title">
      <span v-if="type === 'promote-captain'">{{
        $t("confirm_dialog_promote", {
          name: memberName,
        })
      }}</span>
      <span v-if="type === 'revoke-captain'">{{
        $t("confirm_dialog_revoke", {
          name: memberName,
        })
      }}</span>
      <span v-if="type === 'pass-ownership'">{{
        $t("confirm_dialog_pass_ownership", {
          name: memberName,
        })
      }}</span>
      <span v-if="type === 'remove-athlete'">{{
        $t("confirm_dialog_delete_member", {
          name: memberName,
        })
      }}</span>
    </div>
    <div class="dialog-buttons">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { capitalize } from "lodash";
export default {
  data: () => ({
    colors: [
      "#F4741D",
      "#6DA286",
      "#8F34C1",
      "#2361A7",
      "#84BD00",
      "#CF1019",
      "#FFB800",
    ],
  }),
  props: ["type", "member"],
  computed: {
    getAvatarLetters() {
      return (
        this.member?.first_name?.charAt(0) + this.member?.last_name?.charAt(0)
      );
    },
    defaultAvatarColor() {
      return this.colors[Math.floor(Math.random() * this.colors.length)];
    },
    memberName() {
      return (
        capitalize(this.member?.first_name) +
        " " +
        capitalize(this.member?.last_name)
      );
    },
  },
  methods: {
    closeModal() {
      console.log("close");
      this.$emit("hide-dialog");
    },
  },
};
</script>
<style lang="scss" scoped>
.confirm-dialog {
  background: #ffffff;
  padding-bottom: 60px;
  color: #000000;
  border-radius: 10px;
  @media screen and (max-width: 499px) {
    padding-bottom: 20px;
  }
  .close-dialog {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 10px;
    padding-right: 10px;
    @media screen and (max-width: 499px) {
      padding-top: 14px;
    }
    i {
      color: #000000;
      font-size: 30px;
      cursor: pointer;
      outline: none;
    }
  }
  .dialog-image {
    padding: 30px 0 40px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 499px) {
      padding: 16px 0 34px 0;
    }
    img {
      height: 68px;
      width: 68px;
      border-radius: 100px;
    }
    .default-avatar {
      height: 68px;
      width: 68px;
      border-radius: 100px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 34px;
      font-weight: bold;
      color: #ffffff;
    }
  }
  .dialog-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
    margin-bottom: 46px;
    padding: 0 70px;
    @media screen and (max-width: 499px) {
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 40px;
      padding: 0 40px;
    }
  }
  .dialog-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    button {
      min-width: 139px;
      height: 36px;
      margin: 0 6px;
      font-size: 12px;
      line-height: 15px;
      font-weight: bold;
      @media screen and (max-width: 499px) {
        min-width: 148px;
        margin: 0 8px;
      }
    }
  }
}
</style>