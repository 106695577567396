<template>
  <div class="team-view">
    <div class="team-blocked" v-if="!isLoading && isBlocked">
      <div class="blocked-header">
        <div
          class="back-btn cursor-pointer"
          @click="$router.push({ name: 'teamsList' })"
        >
          <i class="material-icons">arrow_back_ios</i>
          <span>{{ $t("back") }}</span>
        </div>
      </div>
      <div class="blocked-body">
        <i class="material-icons-round"> warning </i>
        <div class="blocked-text">{{ $t("team_is_blocked_page_text") }}</div>
      </div>
    </div>

    <team-hero-block
      :team="team"
      :display="display"
      :isLoading="isLoading"
      v-if="!isBlocked"
    />

    <div class="team-stats" v-if="!isBlocked && !isLoading">
      <div class="stats-title">{{ $t("team_stats") }}</div>
      <div class="stats-list">
        <div class="stats-item">
          <div class="stat-title">{{ $t("events") }}</div>
          <div class="stat-value">{{ transformStat(stats.events) }}</div>
        </div>
        <div class="stats-item">
          <div class="stat-title">{{ $t("distance") }}</div>
          <div class="stat-value">
            {{ transformStat(stats.distance_km) }} <span>km</span>
          </div>
        </div>
        <div class="stats-item">
          <div class="stat-title">{{ $t("obstacles") + "*" }}</div>
          <div class="stat-value">
            {{ transformStat(stats.obstacles) }}
          </div>
        </div>
        <div class="stats-item">
          <div class="stat-title">{{ $t("time_on_course") }}</div>
          <div class="stat-value">
            <div v-if="duration" class="stat-duration">
              <div v-if="duration.days">
                <span>{{ duration.days }}</span>
                <span class="text">{{ $t("d") }} </span>
              </div>
              <div v-if="duration.hours">
                <span>{{ duration.hours }}</span>
                <span class="text">{{ $t("h") }} </span>
              </div>
              <div v-if="duration.minutes">
                <span>{{ duration.minutes }}</span>
                <span class="text">{{ $t("m") }} </span>
              </div>
              <div v-if="duration.seconds">
                <span>{{ duration.seconds }}</span>
                <span class="text">{{ $t("s") }}</span>
              </div>
            </div>
            <div v-if="!duration">0</div>
          </div>
        </div>
      </div>
    </div>
    <div class="team-stats-loading" v-if="isLoading">
      <div class="loading-title">
        <div class="animated"></div>
      </div>
      <div class="loading-list">
        <div class="loading-block" v-for="(i, key) in loadingBlocks" :key="key">
          <div class="animated"></div>
        </div>
      </div>
    </div>
    <div class="team-tabs" v-if="!isBlocked">
      <div class="tabs-list">
        <div
          class="slider-wrapper"
          :style="{ width: `${borderWidth}px`, left: `${borderPosition}px` }"
        >
          <div class="tab-slider"></div>
        </div>
        <div
          ref="leaderboardTab"
          class="team-tab"
          :class="{ 'active-tab': activeTab === 'teamLeaderboard' }"
          @click="$router.push({ name: 'teamLeaderboard' })"
        >
          <span>{{ $t("leaderboard") }}</span>
        </div>
        <div
          ref="upcommingEventsTab"
          class="team-tab"
          :class="{ 'active-tab': activeTab === 'teamEvents' }"
          @click="$router.push({ name: 'teamEvents' })"
        >
          <span>{{ $t("upcoming_events") }}</span>
        </div>
      </div>
    </div>
    <transition :name="transitionName" mode="out-in">
      <router-view key="3" v-if="!isBlocked"></router-view>
    </transition>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import TeamHeroBlock from "./../components/TeamHeroBlock.vue";
export default {
  data: () => ({
    isLoading: true,
    borderPosition: 0,
    borderWidth: 0,
    transitionName: "",
    loadingBlocks: [1, 2, 3, 4],
  }),
  computed: {
    ...mapGetters(["team", "userData"]),
    display() {
      // return "captain"
      if (this.team?.role) return this.team.role;
      return "guest";
    },
    stats() {
      // return this.fakeStats;
      if (!this.team.stats) return {};
      return this.team.stats;
    },
    duration() {
      let seconds = this.stats.time_raced_seconds;
      if (!seconds) return null;
      let d = Math.floor(seconds / (3600 * 24));
      let h = Math.floor((seconds % (3600 * 24)) / 3600);
      let m = Math.floor((seconds % 3600) / 60);
      let s = Math.floor(seconds % 60);
      return {
        days: d,
        hours: h,
        minutes: m,
        seconds: s,
      };
    },
    activeTab() {
      return this.$route.name;
    },
    isBlocked() {
      // sandbox
      // let blocked = false;
      // let role = "member";

      // return (blocked && role === "member") || (blocked && !role);

      // ??
      return (
        (this.team?.blocked && this.team?.role === "member") ||
        (this.team?.blocked && !this.team?.role)
      );
    },
  },
  components: {
    TeamHeroBlock,
  },
  methods: {
    ...mapActions(["getTeam", "handleToolbarNotification"]),
    switchTab() {
      if (this.activeTab === "teamLeaderboard") {
        this.borderPosition = 0;
        this.borderWidth = this.$refs.leaderboardTab?.offsetWidth;
      }
      if (this.activeTab === "teamEvents") {
        this.borderPosition = this.$refs.leaderboardTab?.offsetWidth + 32;
        this.borderWidth = this.$refs.upcommingEventsTab?.offsetWidth;
      }
    },
    transformStat(stat) {
      if (!stat) return 0;
      return stat.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  async mounted() {
    let teamId = this.$router.currentRoute.params.id;
    try {
      await this.getTeam(teamId);
    } catch (err) {
      if (err?.status === 404) await this.$router.push({ name: "teamsList" });
    }
    this.switchTab();
    if (this.team.role === "owner" && this.team.blocked)
      this.handleToolbarNotification({
        type: "warning",
        text: "notification_team_is_blocked",
        isVisible: true,
      });
    this.isLoading = false;
  },
  destroyed() {
    this.handleToolbarNotification({ isVisible: false });
  },
  watch: {
    $route(to) {
      this.switchTab();

      if (to.name === "teamEvents")
        this.transitionName = "slide-x-reverse-transition";
      if (to.name === "teamLeaderboard")
        this.transitionName = "slide-x-transition";
    },
  },
};
</script>
<style lang="scss" scoped>
.team-view {
  max-width: 670px;
  width: 100%;
  padding-bottom: 100px;
  .team-blocked {
    background-color: #121212;
    display: flex;
    flex-direction: column;
    padding: 50px 40px;
    min-height: 364px;
    @media screen and (max-width: 499px) {
      padding: 50px 64px;
      min-height: 518px;
    }
    .blocked-header {
      display: flex;
      @media screen and (max-width: 499px) {
        display: none;
      }
      .back-btn {
        display: flex;
        flex-direction: row;
        align-items: center;

        font-size: 14px;
        font-weight: bold;
        line-height: 14px;
        text-transform: uppercase;

        i {
          font-size: 16px;
          margin-right: 6px;
        }
      }
    }
    .blocked-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 66px;
      @media screen and (max-width: 499px) {
        margin-top: 144px;
      }
      i {
        color: #4f4f4f;
        font-size: 36px;
      }
      .blocked-text {
        max-width: 346px;
        width: 100%;
        text-align: center;
        margin-top: 8px;

        font-size: 14px;
        line-height: 21px;
      }
    }
  }
  .team-stats {
    margin: 64px 0 56px 0;
    @media screen and (max-width: 900px) {
      --stat-padding: 36px;
      margin: 50px 0 56px 0;
    }
    @media screen and (max-width: 499px) {
      --stat-padding: 38px;
      width: 100vw;
      padding-left: 24px;
    }
    @media screen and (max-width: 375px) {
      padding-left: 16px;
    }
    .stats-title {
      font-size: 24px;
      font-weight: bold;
      line-height: 28px;
      text-transform: capitalize;
      margin-bottom: 32px;
      @media screen and (max-width: 900px) {
        font-size: 22px;
        margin-bottom: 24px;
      }
    }
    .stats-list {
      height: 66px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      @media screen and (max-width: 899px) {
        height: 56px;
      }
      .stats-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 0 0 auto;
        padding-right: var(--stat-padding, 42px);
        .stat-title {
          margin-bottom: 6px;
          font-size: 12px;
          line-height: 15px;
          text-transform: capitalize;
          font-weight: 600;
          color: #757474;
        }
        .stat-value {
          font-size: 22px;
          line-height: 24px;
          font-weight: bold;
          text-transform: uppercase;
          .stat-duration {
            display: flex;
            flex-direction: row;
            .text {
              font-size: 11px;
              margin-right: 5px;
              // text-transform: uppercase;
            }
          }
        }
      }
      .stats-item:not(:first-child) {
        padding-left: var(--stat-padding, 42px);
      }
      .stats-item:not(:last-child) {
        border-right: 1px solid rgba(255, 255, 255, 0.2);
      }
    }
    .stats-list::-webkit-scrollbar {
      display: none;
    }
  }
  .team-stats-loading {
    margin: 64px 0 56px 0;
    @media screen and (max-width: 499px) {
      margin-top: 25px;
      padding-left: 16px;
    }
    .loading-title {
      height: 38px;
      width: 150px;
      margin-bottom: 16px;
      @media screen and (max-width: 499px) {
        width: 140px;
        height: 36px;
        margin-bottom: 12px;
      }
    }
    .loading-block {
      height: 70px;
      width: 160px;
      margin-right: 10px;
      @media screen and (max-width: 499px) {
        height: 64px;
        width: 140px;
        margin-right: 8px;
      }
    }
    .loading-title,
    .loading-block {
      background-color: #090909;
      position: relative;
      overflow: hidden;
      .animated {
        @include animatedLine;
      }
    }
    .loading-list {
      display: flex;
      flex-direction: row;
    }
  }
  .team-tabs {
    display: flex;
    height: 42px;
    background-color: #020000;
    flex-direction: row;
    width: 100%;
    // z-index: 20;
    position: relative;

    @media screen and (max-width: 899px) {
      --tabs-border-layout: 48px;
      padding-left: 24px;
    }
    @media screen and (max-width: 499px) {
      --tabs-border-layout: 32px;
      padding-left: 16px;
    }

    .tabs-list {
      display: flex;
      position: relative;
      .slider-wrapper {
        position: absolute;
        height: 3px;
        bottom: 0;
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
        .tab-slider {
          height: 100%;
          width: 100%;
          background-color: #ffffff;
        }
      }
    }
    .team-tab {
      font-size: 14px;
      color: #9d9d9d;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-weight: bold;
      // margin: 0 16px;
      cursor: pointer;
      outline: none;
      margin-right: 32px;
    }
    .active-tab {
      color: #ffffff;
    }
  }
  .team-tabs::after {
    content: " ";
    height: 1px;
    background: rgba(255, 255, 255, 0.2);
    width: calc(100% - var(--tabs-border-layout, 0%));
    position: absolute;
    top: 100%;
    right: 50%;
    transform: translate(50%, -50%);
  }
}
</style>
