<template>
  <div class="search-field" :class="{ 'is-focused': isFocused }">
    <img src="./../../../assets/search.svg" alt="find" />
    <input
      v-model="fieldValue"
      type="text"
      name="search"
      v-bind="$attrs"
      @focus.stop="onFocus"
      @focusout="onBlur"
    />
    <i
      class="material-icons-outlined"
      :class="{ 'is-visible': value }"
      @click="clearField"
    >
      close
    </i>
  </div>
</template>
<script>
export default {
  data: () => ({
    isFocused: false,
  }),
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
  },
  computed: {
    fieldValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
    },
    clearField() {
      this.$emit("input", "");
    },
  },
};
</script>
<style lang="scss" scoped>
.search-field {
  height: 100%;
  border-radius: 30px;
  background-color: #191919;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  img {
    margin-right: 10px;
    height: 14px;
    width: 14px;
  }
  input {
    outline: none;
    color: #ffffff;
    width: 100%;
    margin-right: 10px;
  }
  input::placeholder {
    color: #9d9d9d;
  }
  i {
    font-size: 17px;
    cursor: pointer;
    outline: none;
    opacity: 0;
  }
  .is-visible {
    opacity: 1;
  }
}
.is-focused {
  border: 1px solid #7f7f7f;
  padding: 9px 11px;
  background-color: #292929;
}
</style>
