<template>
  <div class="default-avatar" :style="avatarStyle">
    <template v-if="hasSlot">
      <slot></slot>
    </template>
    <span v-else>{{ avatarLetters }}</span>
  </div>
</template>
<script>
export default {
  data: () => ({
    colors: [
      "#F4741D",
      "#6DA286",
      "#8F34C1",
      "#2361A7",
      "#84BD00",
      // "#CF1019",
      "#FFB800",
    ],
  }),
  props: {
    size: {
      type: Number,
      default: 44,
    },
    text: {
      type: String,
      default: "",
    },
    //TODO: need replace to auto css transform
    fontSize: {
      type: Number,
      default: 24,
    },
  },
  computed: {
    hasSlot() {
      return !!this.$slots.default;
    },
    getColor() {
      return this.colors[Math.floor(Math.random() * this.colors.length)];
    },
    avatarStyle() {
      return {
        background: this.getColor,
        height: `${this.size}px`,
        width: `${this.size}px`,
        "font-size": `${this.fontSize}px`,
      };
    },
    avatarLetters() {
      if (!this.text) return "";

      let fullName = this.text.split(" ");
      return fullName[0]?.charAt(0) + fullName[1]?.charAt(0);
    },
  },
};
</script>
<style lang="scss" scoped>
.default-avatar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  border-radius: 100px;
  font-family: "Druk Cond", "Roboto", sans-serif;
  font-weight: bold;
  line-height: 95%;
  letter-spacing: 0.02em;
  color: #ffffff;
  pointer-events: none;
}
</style>