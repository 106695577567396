<template>
  <div class="crop-dialog">
    <cropper
      ref="cropper"
      :src="imageSrc"
      :stencil-component="stencilComponent"
      :stencil-props="stencilProps"
    />
    <div>
      <slot></slot>
    </div>
    <div class="dialog-buttons">
      <mem-button btn-type="tertiary-dark" @click="close">
        {{ $t("close") }}
      </mem-button>
      <mem-button
        v-if="!uploading"
        btn-type="secondary-dark"
        @click="createBlobEl"
      >
        {{ $t("save") }}
      </mem-button>
      <div v-if="uploading" class="loading-state">
        <v-progress-circular
          indeterminate
          color="#FFFFFF"
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  components: {
    "mem-button": () => import("@/components/base/BaseButton.vue"),
  },
  data: () => ({
    image: null,
    uploading: false,
  }),
  //   type: background_image, avatar
  props: ["imageSrc", "options", "teamId"],
  computed: {
    stencilProps() {
      return {
        aspectRatio: this.options.aspectRatio || 1 / 1,
        movable: true,
        resizable: true,
        handlers: {
          eastNorth: true,
          north: false,
          westNorth: true,
          west: false,
          westSouth: true,
          south: false,
          eastSouth: true,
          east: false,
        },
      };
    },
    stencilComponent() {
      if (this.options.type === "avatar") return "circle-stencil";
      return "rectangle-stencil";
    },
  },
  methods: {
    ...mapActions(["updateTeamImage"]),
    async createBlobEl() {
      const { canvas } = this.$refs.cropper.getResult();
      //   IE hack
      if (!HTMLCanvasElement.prototype.toBlob) {
        this.image = canvas.msToBlob();
        await this.uploadImage();
      } else {
        canvas.toBlob(async (b) => {
          this.image = b;
          await this.uploadImage();
        }, "image/jpeg");
      }
    },
    async uploadImage() {
      if (this.uploading) return;
      const fd = new FormData();
      fd.append(this.options.type, this.image, `${this.options.type}.jpeg`);
      this.uploading = true;
      const res = await this.updateTeamImage({ img: fd, teamId: this.teamId });
      console.log(res, "upload result");
      this.uploading = false;
      this.$emit("close-modal", res);
    },
    close() {
      this.$emit("close-modal", "user close click");
    },
  },
  destroyed() {
    this.uploading = false;
  },
  mounted() {
    console.log(this.options, "dialog options", this.stencilProps);
  },
};
</script>
<style lang="scss" scoped>
.crop-dialog {
  background-color: #383838;
  padding: 10px 10px 20px 10px;
  border-radius: 10px;

  .dialog-buttons {
    display: flex;
    flex-direction: row;
    // align-items: center;
    justify-content: center;
    padding-top: 20px;
    button {
      margin: 0 3px;
    }
    .loading-state {
      padding: 0 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
