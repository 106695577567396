<template>
  <div class="empty-list">
    <div v-if="!simplify" class="default-view">
      <div class="empty-list-text">
        <slot></slot>
        <div v-if="hasButton" class="list-button">
          <find-race-button />
        </div>
      </div>
      <div class="empty-list-delta"></div>
    </div>
    <div v-if="simplify" class="simplify-view">
      <div class="empty-list-text">
        <slot></slot>
      </div>
    </div>
    <div class="footer" v-if="$slots.footer">
      <slot name="footer" />
    </div>
  </div>
</template>
<script>
import FindRaceButton from "./FindRaceButton.vue";
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    simplify: {
      type: Boolean,
      default: false,
    },
    hasButton: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FindRaceButton,
  },
};
</script>
<style lang="scss" scoped>
.empty-list {
  @include elementMarginBottom("M");
  .default-view,
  .simplify-view {
    height: 256px;
    max-width: 670px;
    width: 100%;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.07);
    .empty-list-text {
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.0025em;
      color: #717171;
      max-width: 307px;
      @media screen and (max-width: $mobile) {
        font-size: 14px;
        line-height: 17px;
        max-width: 225px;
      }
      .list-button {
        margin-top: 20px;
      }
    }
  }
  .default-view {
    position: relative;
    overflow: hidden;
    padding-left: 66px;
    @media screen and (max-width: $tablet) {
      padding-left: 46px;
    }
    @media screen and (max-width: $mobile) {
      height: 164px;
      padding-left: 36px;
    }
    .empty-list-delta {
      position: absolute;
      right: -76px;
      background-image: url("../assets/spartan_delta.png");
      background-size: cover;
      // background-size: 80%;
      height: 85%;
      width: 200px;
      @media screen and (max-width: $mobile) {
        height: 70%;
        background-size: 50%;
        right: -140px;
      }
    }
  }
  .simplify-view {
    justify-content: center;
    .empty-list-text {
      text-align: center;
    }
  }

  .footer {
    max-width: 670px;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #bbbbbb;
    margin-top: 24px;

    @media screen and (max-width: $mobile) {
      padding: 0 20px;
    }
  }
}
</style>
