<template>
  <div class="leave-team">
    <div class="close-dialog">
      <i class="material-icons" @click="closeModal"> close </i>
    </div>
    <div class="team-avatar">
      <img v-if="team.avatar" :src="team.avatar" alt="team avatar" />
      <div v-if="!team.avatar" class="default-avatar">
        <i class="material-icons"> groups </i>
      </div>
    </div>
    <div class="team-name">
      <div v-if="canLeave" class="default-text">
        {{ $t("leave") + " " + team.name + "?" }}
      </div>
      <div v-if="!canLeave" class="confirm-text">
        {{ $t("leave_team_confirm") }}
      </div>
      <div class="delete-warning" v-if="showSubline">
        {{ $t("the_team_will_be_deleted") }}
      </div>
    </div>
    <div class="dialog-buttons">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: ["team", "showSubline", "canLeave"],
  methods: {
    closeModal() {
      console.log("close");
      this.$emit("hide-dialog");
    },
  },
};
</script>
<style lang="scss" scoped>
.leave-team {
  background: #ffffff;
  padding-bottom: 60px;
  color: #000000;
  border-radius: 10px;
  @media screen and (max-width: 499px) {
    padding-bottom: 20px;
  }
  .close-dialog {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 10px;
    padding-right: 10px;
    @media screen and (max-width: 499px) {
      padding-top: 14px;
    }
    i {
      color: #000000;
      font-size: 30px;
      cursor: pointer;
      outline: none;
    }
  }
  .team-avatar {
    padding: 30px 0 40px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 499px) {
      padding: 16px 0 34px 0;
    }
    img {
      height: 68px;
      width: 68px;
      border: 1px solid #000000;
      border-radius: 100px;
    }
    .default-avatar {
      height: 68px;
      width: 68px;
      border: 1px solid #000000;
      border-radius: 100px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      i {
        color: #000000;
        font-size: 46px;
      }
    }
  }
  .team-name {
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
    margin-bottom: 46px;
    .default-text {
      padding: 0 72px;
      @media screen and (max-width: 499px) {
        padding: 0 40px;
      }
    }
    .confirm-text {
      padding: 0 52px;
      @media screen and (max-width: 499px) {
        padding: 0 40px;
      }
    }
    .delete-warning {
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #191818;
      text-align: center;
      margin-top: 20px;
    }
    @media screen and (max-width: 499px) {
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 40px;
    }
  }
  .dialog-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    button {
      min-width: 139px;
      height: 36px;
      margin: 0 6px;
      font-size: 12px;
      line-height: 15px;
      font-weight: bold;
      @media screen and (max-width: 499px) {
        min-width: 148px;
        margin: 0 8px;
      }
    }
  }
}
</style>