<template>
  <div class="team-events">
    <!-- events list -->
    <template v-if="!isLoading">
      <template v-if="currentRole !== 'guest'">
        <template v-if="teamEvents.length === 0">
          <empty-list has-button>{{ $t("el_no_upcoming_events") }}</empty-list>
        </template>
        <template v-if="teamEvents.length > 0">
          <template v-for="(event, index) in teamEvents">
            <event-card
              :key="index"
              :teamEvent="true"
              :teamId="teamId"
              :event="event"
              :pic="event.image_small"
              :startDate="event.starts"
              :isClickable="false"
            />
          </template>
        </template>
      </template>
      <div v-if="currentRole === 'guest'">
        <empty-list>{{ $t("el_upcoming_events_not_visible") }}</empty-list>
      </div>
    </template>
    <template v-if="isLoading">
      <div class="list-loading">
        <div class="loading-line" v-for="(i, index) in [1, 2]" :key="index">
          <div class="animated"></div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import EventCard from "../../../components/EventCard.vue";
import EmptyList from "../../../components/EmptyList.vue";
export default {
  data: () => ({
    teamId: "",
    isLoading: true,
  }),
  computed: {
    ...mapGetters(["team", "teamEvents"]),
    currentRole() {
      if (this.team?.role) return this.team.role;
      return "guest";
    },
  },
  components: {
    EventCard,
    EmptyList,
  },
  methods: {
    ...mapActions(["getTeamEvents"]),
  },
  async mounted() {
    this.teamId = this.$router.currentRoute.params.id;
    await this.getTeamEvents(this.teamId);
    this.isLoading = false;
  },
};
</script>
<style lang="scss" scoped>
.team-events {
  padding: 40px 0 60px 0;
  .list-loading {
    .loading-line {
      height: 136px;
      background-color: #090909;
      position: relative;
      overflow: hidden;
      margin-bottom: 12px;
      @media screen and (max-width: 499px) {
        height: 156px;
        margin-bottom: 8px;
      }
      .animated {
        @include animatedLine;
      }
    }
  }
}
</style>