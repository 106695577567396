<template>
  <div class="team-leaderboard">
    <div class="leaderboard-toolbar">
      <div class="lb-filters">
        <!-- race type select -->
        <v-menu transition="slide-y-transition" bottom right>
          <template v-slot:activator="{ on, attrs }">
            <div class="filter-race" v-bind="attrs" v-on="on">
              <div class="selected-type">
                {{ selectedType ? selectedType : $t("all_races") }}
              </div>
              <i class="material-icons-outlined"> arrow_drop_down </i>
            </div>
          </template>
          <div class="race-type-menu">
            <div class="menu-item">
              <div
                :class="{ 'is-active': !selectedType }"
                @click="changeCategory({})"
              >
                {{ $t("all_races") }}
              </div>
            </div>
            <div
              class="menu-item"
              v-for="(type, index) in filterCategories"
              :key="index"
            >
              <div
                :class="{ 'is-active': type === selectedType }"
                @click="changeCategory(type)"
              >
                {{ type.name }}
              </div>
            </div>
          </div>
        </v-menu>
        <!-- year select -->
        <v-menu transition="slide-y-transition" bottom right>
          <template v-slot:activator="{ on, attrs }">
            <div class="filter-year" v-bind="attrs" v-on="on">
              <div>
                {{ selectedYear ? selectedYear : $t("all_time") }}
              </div>
              <i class="material-icons-outlined"> arrow_drop_down </i>
            </div>
          </template>
          <div class="year-menu">
            <div class="menu-item">
              <div
                @click="changeYear('')"
                :class="{ 'is-active': !selectedYear }"
              >
                {{ $t("all_time") }}
              </div>
            </div>
            <div
              class="menu-item"
              v-for="(year, index) in filterYears"
              :key="index"
            >
              <div
                @click="changeYear(year)"
                :class="{ 'is-active': year === selectedYear }"
              >
                {{ year }}
              </div>
            </div>
          </div>
        </v-menu>
      </div>
      <div class="filters-sidebar">
        <div class="filters-btn" @click="toggleFilterSidebar">
          <span>{{ $t("filters") }}</span>
        </div>
        <transition name="slide-x-transition" mode="out-in">
          <div v-if="showFiltersSidebar" class="sidebar-body">
            <sidebar-filters
              :filtersType="'teamLeaderboard'"
              v-on:close-sidebar="toggleFilterSidebar"
              v-on:apply-filters="applyFilters"
            />
          </div>
        </transition>
        <transition name="fade-transition" mode="out-in">
          <div
            v-if="showFiltersSidebar"
            class="sidebar-overlay"
            @click="toggleFilterSidebar"
          ></div>
        </transition>
      </div>
      <div class="lb-search">
        <search-input
          v-model="search"
          v-on:input="searchMember"
          :placeholder="$t('search')"
        ></search-input>
      </div>
    </div>
    <!-- desktop table -->
    <div class="leaderboard-table">
      <!-- table header -->
      <div
        class="table-header"
        v-if="(members && members.length !== 0) || isLoading"
      >
        <div class="header-column"></div>
        <div class="header-column"></div>
        <!-- races -->
        <div
          class="header-column column-gap cursor-pointer"
          :class="{ 'column-active': activeSort === 'events' }"
          @click="changeSort('events')"
        >
          <i
            class="material-icons-outlined"
            :class="{ 'sort-asc': sortDirection === 'asc' }"
          >
            arrow_downward
          </i>
          <span>{{ $t("races") }}</span>
        </div>
        <!-- distance_km -->
        <div
          class="header-column column-gap cursor-pointer"
          :class="{ 'column-active': activeSort === 'distance_km' }"
          @click="changeSort('distance_km')"
        >
          <i
            class="material-icons-outlined"
            :class="{ 'sort-asc': sortDirection === 'asc' }"
          >
            arrow_downward
          </i>
          <span>{{ $t("distance") }}</span>
        </div>
        <!-- top_finish -->
        <div
          class="header-column column-gap cursor-pointer"
          :class="{ 'column-active': activeSort === 'top_finish' }"
          @click="changeSort('top_finish')"
        >
          <i
            class="material-icons-outlined"
            :class="{ 'sort-asc': sortDirection === 'asc' }"
          >
            arrow_downward
          </i>
          <span>{{ $t("top_finish") }}</span>
        </div>
        <!-- trifectas -->
        <div
          class="header-column last-column cursor-pointer"
          :class="{ 'column-active': activeSort === 'trifectas' }"
          @click="changeSort('trifectas')"
        >
          <i
            class="material-icons-outlined"
            :class="{ 'sort-asc': sortDirection === 'asc' }"
          >
            arrow_downward
          </i>
          <span>{{ $t("trifecta") }}</span>
        </div>
        <div class="header-column"></div>
      </div>
      <!-- table body -->
      <template v-if="!isLoading">
        <template v-for="(member, index) in members">
          <table-row
            :key="member.id"
            :member="member"
            :rowIndex="index"
            :currentRole="currentRole"
            :searchState="searchState"
            v-on:show-confirm="openConfirmDialog"
            v-on:leave-team="showLeaveDialog = true"
            class="table-row"
          />
        </template>
        <template v-if="teamMembersBottom.length !== 0 && showHiddenMembersRow">
          <div class="hidden-members cursor-pointer" @click="showHiddenMembers">
            <div class="more-icon">
              <i class="material-icons-outlined"> more_horiz </i>
            </div>
            <div class="members-count">
              {{ hiddenMembersCount + " " + $t("hidden_members") }}
            </div>
          </div>
        </template>
        <template v-for="(member, index) in bottomList">
          <table-row
            :key="member.id"
            :member="member"
            :rowIndex="index"
            :currentRole="currentRole"
            :searchState="searchState"
            v-on:show-confirm="openConfirmDialog"
            v-on:leave-team="showLeaveDialog = true"
            class="table-row"
          />
        </template>
      </template>
      <!-- table skeleton -->
      <template v-if="isLoading">
        <div class="list-loading">
          <div
            class="loading-line"
            v-for="(i, index) in [1, 2, 3]"
            :key="index"
          >
            <div class="animated"></div>
          </div>
        </div>
      </template>
    </div>
    <!-- mobile list -->
    <div class="leaderboard-list">
      <template v-if="!isLoading">
        <template v-for="(member, index) in members">
          <member-card
            :key="member.id"
            :member="member"
            :rowIndex="index"
            :currentRole="currentRole"
            :searchState="searchState"
            class="member-card"
            v-on:show-confirm="openConfirmDialog"
            v-on:leave-team="showLeaveDialog = true"
          ></member-card>
        </template>

        <template v-if="teamMembersBottom.length !== 0 && showHiddenMembersRow">
          <div class="hidden-members cursor-pointer" @click="showHiddenMembers">
            <div class="more-icon">
              <i class="material-icons-outlined"> more_horiz </i>
            </div>
            <div class="members-count">
              {{ hiddenMembersCount + " " + $t("hidden_members") }}
            </div>
          </div>
        </template>

        <template v-for="(member, index) in bottomList">
          <member-card
            :key="member.id"
            :member="member"
            :rowIndex="index"
            :currentRole="currentRole"
            :searchState="searchState"
            class="member-card"
            v-on:show-confirm="openConfirmDialog"
            v-on:leave-team="showLeaveDialog = true"
          ></member-card>
        </template>
      </template>
      <template v-if="isLoading">
        <div class="list-loading">
          <div
            class="loading-line"
            v-for="(i, index) in [1, 2, 3]"
            :key="index"
          >
            <div class="animated"></div>
          </div>
        </div>
      </template>
    </div>
    <!-- empty state -->
    <div
      class="leaderboard-empty"
      v-if="members && members.length === 0 && !isLoading"
    >
      <span>{{ $t("team_member_not_found") }}</span>
    </div>

    <div class="list-button" v-if="showLoadMoreButton && !isLoading">
      <mem-button
        :btnType="'tertiary-dark'"
        :loading="membersLoading"
        @click="loadMembers"
        >{{ $t("load_more") }}</mem-button
      >
    </div>
    <!-- leave team modal -->
    <v-dialog
      v-model="showLeaveDialog"
      overlay-color="#000000"
      overlay-opacity="0.54"
      :max-width="410"
      class="mem-dialog"
    >
      <leave-dialog
        :team="team"
        :showSubline="canDeleteTeam"
        :canLeave="canLeaveTeam"
        v-on:hide-dialog="showLeaveDialog = false"
      >
        <div>
          <template v-if="!canLeaveTeam">
            <mem-button
              @click="showLeaveDialog = false"
              :btnType="'mem-primary'"
              >{{ $t("ok") }}</mem-button
            >
          </template>
          <template v-if="canLeaveTeam">
            <mem-button
              @click="showLeaveDialog = false"
              :btnType="'tertiary-light'"
              >{{ $t("cancel") }}</mem-button
            >
            <mem-button
              v-if="!canDeleteTeam"
              @click="leaveCurrentTeam"
              :btnType="'mem-primary'"
              >{{ $t("leave") }}</mem-button
            >
            <mem-button
              v-if="canDeleteTeam"
              @click="deleteCurrentTeam"
              :btnType="'mem-primary'"
              >{{ $t("leave") }}</mem-button
            ></template
          >
        </div>
      </leave-dialog>
    </v-dialog>
    <!-- confirm modal -->
    <v-dialog
      v-model="showConfirmDialog"
      overlay-color="#000000"
      overlay-opacity="0.54"
      :max-width="410"
      class="mem-dialog"
    >
      <confirm-dialog
        :type="confirmDialogType"
        :member="selectedMember"
        v-on:hide-dialog="showConfirmDialog = false"
      >
        <div>
          <mem-button
            @click="showConfirmDialog = false"
            :btnType="'tertiary-light'"
            >{{ $t("cancel") }}</mem-button
          >
          <mem-button
            v-if="confirmDialogType === 'promote-captain'"
            @click="changeRole('captain')"
            :btnType="'mem-primary'"
            >{{ $t("yes") }}</mem-button
          >
          <mem-button
            v-if="confirmDialogType === 'revoke-captain'"
            @click="changeRole('member')"
            :btnType="'mem-primary'"
            >{{ $t("yes") }}</mem-button
          >
          <mem-button
            v-if="confirmDialogType === 'pass-ownership'"
            @click="changeRole('owner')"
            :btnType="'mem-primary'"
            >{{ $t("yes") }}</mem-button
          >
          <mem-button
            v-if="confirmDialogType === 'remove-athlete'"
            @click="removeAthlete"
            :btnType="'mem-primary'"
            >{{ $t("yes") }}</mem-button
          >
        </div>
      </confirm-dialog>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import BaseButton from "./../../../components/base/BaseButton.vue";
import LeaderboardTableRow from "./LeaderboardTableRow.vue";
import LeaderboardMemberCard from "./LeaderboardMemberCard.vue";
import LeaveTeamDialog from "../components/LeaveTeamDialog.vue";
import ConfirmDialog from "../components/ConfirmDialog.vue";
import SearchInput from "../components/SearchInput.vue";
import { head, last, filter, map, isEmpty } from "lodash";
import SidebarFilters from "../../../components/SidebarFilters.vue";
export default {
  components: {
    "mem-button": BaseButton,
    "table-row": LeaderboardTableRow,
    "member-card": LeaderboardMemberCard,
    "leave-dialog": LeaveTeamDialog,
    ConfirmDialog,
    SidebarFilters,
    SearchInput,
  },
  data: () => ({
    isLoading: true,
    selectedType: "",
    selectedYear: "",
    membersLoading: false,
    search: "",
    searchState: false,
    showFiltersSidebar: false,
    showLeaveDialog: false,
    showConfirmDialog: false,
    // confirm dialog types: promote-captain, revoke-captain, pass-ownership, remove-athlete
    confirmDialogType: "",
    selectedMember: {},
  }),
  computed: {
    ...mapGetters([
      "userData",
      "team",
      "teamMembers",
      "teamMembersBottom",
      "filterCategories",
      "filterYears",
      "leaderboardParams",
    ]),
    currentRole() {
      if (this.team?.role) return this.team.role;
      return "guest";
    },
    members() {
      return this.teamMembers;
    },
    activeSort() {
      return this.leaderboardParams.sort;
    },
    sortDirection() {
      return this.leaderboardParams.direction;
    },
    listLastMember() {
      return last(this.members);
    },
    bottomListFirstMember() {
      return head(this.teamMembersBottom);
    },
    bottomListLastMember() {
      return last(this.teamMembersBottom);
    },
    showHiddenMembersRow() {
      return this.listLastMember?.rank + 1 < this.bottomListFirstMember?.rank;
    },
    hiddenMembersCount() {
      return this.bottomListFirstMember?.rank - this.listLastMember?.rank - 1;
    },
    bottomList() {
      let bottomIds = map(this.teamMembersBottom, "id");
      let duplicates = filter(this.members, (member) => {
        return bottomIds.indexOf(member.id) >= 0;
      });
      let duplicatesIds = map(duplicates, "id");
      return filter(this.teamMembersBottom, (member) => {
        return duplicatesIds.indexOf(member.id) === -1;
      });
    },
    showLoadMoreButton() {
      return (
        this.bottomListLastMember?.rank !== this.leaderboardParams.total &&
        this.members.length !== this.leaderboardParams.total
      );
    },
    canDeleteTeam() {
      return this.currentRole === "owner" && this.leaderboardParams.total === 1;
    },
    currentUserInTop() {
      return !isEmpty(filter(this.members, { id: this.userData.id }));
    },
    canLeaveTeam() {
      if (this.currentRole === "owner" && this.leaderboardParams.total > 1)
        return false;
      return true;
    },
  },
  methods: {
    ...mapActions([
      "leaveTeam",
      "getTeamMembers",
      "getLeaderboardFilters",
      "changeLeaderboardSort",
      "updateLeaderboardSort",
      "clearLeaderboardList",
      "applyLeaderboardFilters",
      "changeLeaderboardSearch",
      "changeTeamMemberRole",
      "removeTeamMember",
      "deleteTeam",
    ]),
    async changeCategory(category) {
      this.applyLeaderboardFilters({
        category: category.id || "",
        year: this.leaderboardParams.year,
      });
      this.selectedType = category.name || "";
      await this.getTeamMembers({});
    },
    async changeYear(year) {
      this.applyLeaderboardFilters({
        year,
        category: this.leaderboardParams.category,
      });
      this.selectedYear = year;
      await this.getTeamMembers({});
    },
    changeSort(sort) {
      this.changeLeaderboardSort(sort);
    },
    async showHiddenMembers() {
      let queryLimit;
      if (this.hiddenMembersCount > 15) queryLimit = 15;
      else queryLimit = this.hiddenMembersCount;

      await this.getTeamMembers({
        queryType: "loadHidden",
        queryLimit: queryLimit,
      });
      console.log("show");
    },
    async loadMembers() {
      this.membersLoading = true;
      if (this.search.length > 2) {
        await this.getTeamMembers({
          queryType: "search",
        });
      } else {
        let queryOffset = this.bottomListLastMember?.rank + 1;
        if (this.currentUserInTop) {
          await this.getTeamMembers({
            queryType: "loadHidden",
            queryLimit: 15,
          });
        } else {
          await this.getTeamMembers({
            queryType: "loadMore",
            queryOffset: queryOffset,
          });
        }
      }
      this.membersLoading = false;
    },
    async searchMember() {
      let teamId = this.$router.currentRoute.params.id;
      if (!this.search) {
        this.changeLeaderboardSearch("");
        await this.getTeamMembers({
          teamId: teamId,
          queryType: "default",
        });
        this.searchState = false;
      }
      if (this.search.length <= 2) return;
      this.changeLeaderboardSearch(this.search);
      await this.getTeamMembers({});
      this.searchState = true;
    },
    toggleFilterSidebar() {
      this.showFiltersSidebar = !this.showFiltersSidebar;
    },
    async applyFilters(params) {
      let { sortBy, sortDirection, category, year } = params;
      this.updateLeaderboardSort({ sort: sortBy, direction: sortDirection });
      this.applyLeaderboardFilters({ category, year });
      await this.getTeamMembers({});
      console.log("apply leaderboard", params);
    },
    async leaveCurrentTeam() {
      await this.leaveTeam();
      await this.getTeamMembers({});
      this.showLeaveDialog = false;
    },
    openConfirmDialog({ member, action }) {
      this.confirmDialogType = action;
      this.selectedMember = member;
      this.showConfirmDialog = true;
    },
    async changeRole(role) {
      console.log(role);
      await this.changeTeamMemberRole({
        memberId: this.selectedMember.id,
        role: role,
      });
      this.showConfirmDialog = false;
    },
    async removeAthlete() {
      console.log("remove athlete");
      await this.removeTeamMember(this.selectedMember.id);
      this.showConfirmDialog = false;
    },
    async deleteCurrentTeam() {
      let teamId = this.$router.currentRoute.params.id;
      await this.deleteTeam(teamId);
      this.$router.push({ path: "/teams" });
      console.log("delete");
    },
  },
  async mounted() {
    let teamId = this.$router.currentRoute.params.id;
    await this.getLeaderboardFilters(teamId);
    await this.getTeamMembers({ teamId: teamId, queryType: "default" });
    this.isLoading = false;
  },
  destroyed() {
    this.clearLeaderboardList();
  },
};
</script>
<style lang="scss" scoped>
$grey: #9d9d9d;

@mixin defaultText {
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
}
@mixin textBold {
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
}
@mixin defaultAvatar($size) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  height: $size;
  width: $size;
  background: red;
}
.race-type-menu,
.year-menu {
  background-color: #191919;
  border-radius: 18px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  text-transform: capitalize;

  .menu-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    div {
      border-radius: 15px;
      cursor: pointer;
      outline: none;
    }
    .is-active,
    div:hover {
      background: #3e3e3e;
    }
  }
}
.sort-asc {
  transform: rotate(180deg);
}
.race-type-menu {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px 36px;
  padding: 36px 22px;
  .menu-item {
    div {
      padding: 8px 20px;
    }
  }
}
.year-menu {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  padding: 30px 34px 30px 26px;
  .menu-item {
    div {
      padding: 8px 20px 8px 10px;
    }
  }
}
::v-deep(.v-menu__content) {
  box-shadow: none;
}
.team-leaderboard {
  .leaderboard-toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 26px;
    @media screen and (max-width: 899px) {
      justify-content: flex-start;
      padding: 40px 28px;
    }
    @media screen and (max-width: 375px) {
      justify-content: flex-start;
      padding: 45px 16px;
    }
    .filters-sidebar {
      .filters-btn {
        height: 44px;
        border-radius: 30px;
        background: #191919;
        font-size: 14px;
        line-height: 17px;
        text-transform: uppercase;
        padding: 0 23px;
        margin-right: 8px;
        display: none;
        flex-direction: row;
        align-items: center;
        @media screen and (max-width: 899px) {
          display: flex;
        }
      }
      .sidebar-body {
        width: 445px;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 999999;
        background: #ffffff;
        // width: 0;
        @media screen and (max-width: 499px) {
          width: 85%;
        }
      }
      .sidebar-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background: #000000;
        opacity: 0.5;
        z-index: 3;
      }
    }
    // lb - leaderboard
    .lb-filters {
      display: flex;
      flex-direction: row;
      gap: 12px;
      .filter-race,
      .filter-year {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        outline: none;

        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0.04em;
        text-transform: uppercase;

        padding: 10px 16px;
        background: #191919;
        border-radius: 19px;
        i {
          font-size: 18px;
        }
      }
      .filter-race {
        width: 182px;
        .selected-type {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .filter-year {
        width: 140px;
      }
      @media screen and (max-width: 899px) {
        display: none;
      }
    }
    .lb-search {
      width: 260px;
      height: 38px;
      @media screen and (max-width: 899px) {
        height: 44px;
        width: 194px;
      }
    }
  }
  .leaderboard-table {
    display: grid;
    grid-template-columns:
      58px minmax(max-content, auto) repeat(4, minmax(auto, max-content))
      54px;
    background: #000000;
    .table-header,
    .table-row {
      display: contents;
    }
    .table-header {
      .header-column {
        @include defaultText;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: $grey;
        text-transform: uppercase;
        padding: 20px 0 18px 0;
        position: relative;
        i {
          font-size: 16px;
          margin-right: 2px;
          opacity: 0;
          position: absolute;
          left: -1px;
        }
      }
      .header-column:hover {
        color: #ffffff;
      }
      .column-active::before {
        content: "";
        position: absolute;
        bottom: 0;
        height: 3px;
        width: var(--filter-line-width, 90%);
        left: -2px;
        background: #ffffff;
      }
      .column-active {
        position: relative;
        color: #ffffff;
        i {
          opacity: 1;
        }
      }
      .column-gap {
        padding-right: 17px;
        padding-left: 17px;
        white-space: nowrap;
      }
      .last-column {
        --filter-line-width: 110%;
        padding-left: 17px;
        white-space: nowrap;
      }
    }
    .colored-row {
      .row-column {
        background-color: #121212;
      }
    }
    .hidden-members {
      height: 70px;
      grid-column: 1/-1;
      background: #000000;
      display: flex;
      flex-direction: row;
      align-items: center;
      .more-icon {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 58px;
      }
      .members-count {
        padding-left: 84px;
        font-size: 12px;
        line-height: 15px;
        font-weight: bold;
        text-transform: uppercase;
        color: #808080;
      }
    }
    .list-loading {
      grid-column: 1/-1;
      .loading-line {
        height: 70px;
        background-color: #090909;
        position: relative;
        overflow: hidden;
        margin-bottom: 4px;
        .animated {
          @include animatedLine;
        }
      }
    }
    @media screen and (max-width: 899px) {
      display: none;
    }
  }
  .leaderboard-list {
    display: none;
    @media screen and (max-width: 899px) {
      display: block;
    }
    .hidden-members {
      display: grid;
      grid-template-columns: 95px 1fr;
      align-items: center;
      height: 70px;
      @media screen and (max-width: 375px) {
        grid-template-columns: 55px 1fr;
      }
      .more-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          color: #f4f4f4;
        }
      }
      .members-count {
        font-size: 14px;
        line-height: 17px;
        font-weight: bold;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #7a7a7a;
      }
    }
    .list-loading {
      .loading-line {
        height: 122px;
        background-color: #090909;
        position: relative;
        overflow: hidden;
        margin-bottom: 8px;
        .animated {
          @include animatedLine;
        }
      }
    }
  }
  .leaderboard-empty {
    height: 240px;
    background-color: #121212;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 9px;
    span {
      font-size: 20px;
      line-height: 24px;
      font-weight: bold;
      color: #717171;
      letter-spacing: 0.0025em;
      text-align: center;
      max-width: 370px;
      @media screen and (max-width: 499px) {
        font-size: 16px;
        line-height: 22px;
        max-width: 320px;
      }
    }
  }
  .list-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin-top: 30px;
  }
}
</style>