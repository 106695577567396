<template>
  <div class="report-team">
    <div class="close-report">
      <i class="material-icons" @click="close"> close </i>
    </div>
    <div class="report-title">{{ $t("report_team_dialog_title") }}</div>
    <div class="report-description">
      {{ $t("report_team_dialog_description") }}
    </div>
    <div class="report-list">
      <div
        v-for="(report, index) in reportTypes"
        :key="index"
        class="report-item"
      >
        <v-simple-checkbox
          v-model="report.selected"
          color="#cf1019"
          v-ripple="false"
        ></v-simple-checkbox>
        <div class="item-label">{{ $t(report.key) }}</div>
      </div>
    </div>
    <div class="report-input">
      <v-text-field
        v-model="optionalText"
        :label="$t('report_team_dialog_input_label')"
        color="secondary"
      ></v-text-field>
    </div>
    <div class="report-button">
      <mem-button
        btn-type="secondary-light"
        @click="report"
        :disabled="isDisabled"
        >{{ $t("report") }}</mem-button
      >
    </div>
  </div>
</template>
<script>
import { map, filter } from "lodash";
import { mapActions } from "vuex";
export default {
  components: {
    "mem-button": () => import("@/components/base/BaseButton.vue"),
  },
  data: () => ({
    reportTypes: [
      {
        key: "violence",
        selected: false,
      },
      {
        key: "harassment",
        selected: false,
      },
      {
        key: "false_information",
        selected: false,
      },
      {
        key: "spam",
        selected: false,
      },
      {
        key: "hate_speech",
        selected: false,
      },
      {
        key: "copyright_infringement",
        selected: false,
      },
    ],
    optionalText: "",
  }),
  props: ["team"],
  computed: {
    isDisabled() {
      return this.activeTypes.length === 0;
    },
    activeTypes() {
      return filter(this.reportTypes, { selected: true });
    },
  },
  methods: {
    ...mapActions(["reportTeam", "handleToolbarNotification"]),
    close() {
      this.$emit("close-report");
    },
    async report() {
      let list = map(this.activeTypes, "key").join(", ");
      await this.reportTeam({
        teamId: this.team.id,
        reason: list,
        details: this.optionalText,
      });
      this.handleToolbarNotification({
        type: "success",
        text: "report_sent",
        isVisible: true,
        canClose: true,
      });
      this.close();
      setTimeout(() => {
        this.handleToolbarNotification({
          isVisible: false,
        });
      }, 3000);
      // console.log(list, "list");
      // console.table(this.reportTypes);
    },
  },
};
</script>
<style lang="scss" scoped>
.report-team {
  padding: 24px 20px 24px 20px;
  background-color: #ffffff;
  color: #121212;
  border-radius: 10px;
  .close-report {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
    height: 15px;
    i {
      position: absolute;
      color: #c6c6c6;
      cursor: pointer;
      outline: none;
      font-size: 26px;
      right: -5px;
      top: -5px;
    }
  }
  .report-title {
    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 4px 0 24px 0;
    letter-spacing: 0.04em;
  }
  .report-description {
    font-size: 12px;
    line-height: 17px;
    font-weight: 600;
    margin-bottom: 36px;
  }
  .report-list {
    margin-bottom: 50px;
    .report-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 19px 0;
      ::v-deep(.v-simple-checkbox) {
        i {
          font-size: 26px;
        }
      }
      .item-label {
        margin-left: 6px;
        font-size: 14px;
        line-height: 17px;
        font-weight: 600;
        // text-transform: capitalize;
      }
    }
  }
  .report-input {
    ::v-deep(.v-label) {
      text-transform: none;
    }
  }
  .report-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
    button {
      padding: 0 36px;
      height: 42px;
      font-size: 12px;
      line-height: 15px;
      font-weight: bold;
    }
  }
}
</style>