<template>
  <div
    class="lb-member-card"
    :class="[{ 'colored-card': !isEven, 'current-user': isCurrentUser }]"
  >
    <div class="user-position">{{ member.rank }}</div>
    <div class="card-body">
      <div
        class="user-details"
        :class="{ 'has-spartan-plus': member.spartan_plus }"
        @click="openPublicProfile"
      >
        <div
          class="captain-icon"
          v-if="member.role === 'captain' || member.role === 'owner'"
        >
          <i class="material-icons" v-if="member.role === 'captain'">
            star_rate
          </i>
          <v-icon v-if="member.role === 'owner'">mdi-crown</v-icon>
        </div>
        <div
          v-if="!member.avatar"
          class="default-avatar"
          :style="{ 'background-color': defaultAvatarColor }"
        >
          <span>{{ getAvatarLetters }}</span>
        </div>
        <div v-if="member.avatar" class="user-avatar">
          <img :src="member.avatar" alt="photo" />
        </div>
        <div class="user-name">
          <div class="user-name-wrap">
            <span class="user-name-text">{{ memberName }}</span>
            <span class="user-name-status" v-if="member.spartan_plus"
              >Spartan+</span
            >
          </div>
          <v-dialog
            v-model="cardDialog"
            overlay-color="#000000"
            overlay-opacity="0.54"
            transition="slide-y-reverse-transition"
            :max-width="410"
            class="mem-dialog"
          >
            <template v-slot:activator="{ on, attrs }" v-if="showMoreButton">
              <div class="more-button" v-bind="attrs" v-on="on">
                <i class="material-icons-outlined"> more_horiz </i>
              </div>
            </template>
            <div class="card-dialog">
              <div class="dialog-item" style="display: none">
                <i class="material-icons"> person_add_alt_1 </i>
                <span>{{ $t("follow_athlete") }}</span>
              </div>
              <div class="dialog-item" style="display: none">
                <i class="material-icons"> person_remove_alt_1 </i>
                <span>{{ $t("unfollow_athlete") }}</span>
              </div>

              <div
                v-if="isOwner && member.role === 'member' && !isCurrentUser"
                class="dialog-item"
                @click="closeDialog('promote-captain')"
              >
                <i class="material-icons"> star </i>
                <span>{{ $t("promote_to_captain") }}</span>
              </div>
              <div
                v-if="isOwner && member.role === 'captain' && !isCurrentUser"
                class="dialog-item"
                @click="closeDialog('revoke-captain')"
              >
                <i class="material-icons"> star </i>
                <span>{{ $t("revoke_captainship") }}</span>
              </div>
              <div
                v-if="isOwner && !isCurrentUser"
                class="dialog-item"
                @click="closeDialog('pass-ownership')"
              >
                <!-- <i class="material-icons"> star </i> -->
                <v-icon>mdi-crown</v-icon>
                <span>{{ $t("pass_team_ownership") }}</span>
              </div>
              <div
                v-if="
                  (isOwner || isCaptain) &&
                  !isCurrentUser &&
                  member.role !== 'owner'
                "
                class="dialog-item"
                @click="closeDialog('remove-athlete')"
              >
                <i class="material-icons"> delete </i>
                <span>{{ $t("remove_athlete") }}</span>
              </div>
              <div v-if="isCurrentUser" class="dialog-item" @click="leaveTeam">
                <i class="material-icons"> delete </i>
                <span>{{ $t("leave_team") }}</span>
              </div>
            </div>
          </v-dialog>
        </div>
      </div>
      <div class="user-stats">
        <div class="stats-title">{{ $t("races") }}</div>
        <div class="stats-title">{{ $t("distance") }}</div>
        <div class="stats-title">{{ $t("top_finish") }}</div>
        <div class="stats-title">{{ $t("trifecta") }}</div>
        <div>{{ stats.events || "--" }}</div>
        <div>{{ stats.distance_km ? stats.distance_km + " km" : "--" }}</div>
        <div>{{ topFinish || "--" }}</div>
        <div>{{ stats.trifectas || "--" }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { capitalize } from "lodash";
import { mapGetters } from "vuex";
import localizeResolver from "@/common/mixins/localizeResolver";

export default {
  mixins: [localizeResolver],
  data: () => ({
    colors: [
      "#F4741D",
      "#6DA286",
      "#8F34C1",
      "#2361A7",
      "#84BD00",
      "#CF1019",
      "#FFB800",
    ],
    cardDialog: false,
  }),
  props: ["member", "rowIndex", "currentRole", "searchState"],
  computed: {
    ...mapGetters(["userData", "specificLinks"]),
    isEven() {
      return this.member.rank % 2 == 0;
    },
    isOwner() {
      return this.currentRole === "owner";
    },
    isCaptain() {
      return this.currentRole === "captain";
    },
    isCurrentUser() {
      return this.userData?.id === this.member?.id;
    },
    stats() {
      if (!this.member.stats) return {};
      return this.member.stats;
    },
    getAvatarLetters() {
      return (
        this.member?.first_name?.charAt(0) + this.member?.last_name?.charAt(0)
      );
    },
    defaultAvatarColor() {
      return this.colors[Math.floor(Math.random() * this.colors.length)];
    },
    showMoreButton() {
      return (
        this.currentRole === "owner" ||
        (this.currentRole === "captain" && this.member.role !== "owner") ||
        this.isCurrentUser
      );
    },
    topFinish() {
      if (
        !this.stats?.top_finish_format?.place &&
        !this.stats?.top_finish_format?.group
      )
        return "";
      return `${this.stats.top_finish_format.place} ${
        this.stats.top_finish_format.group || ""
      }`;
    },
    memberName() {
      return (
        capitalize(this.member?.first_name) +
        " " +
        capitalize(this.member?.last_name?.charAt(0)) +
        "."
      );
    },
  },
  methods: {
    closeDialog(action) {
      this.cardDialog = false;
      this.$emit("show-confirm", { member: this.member, action });
    },
    leaveTeam() {
      this.cardDialog = false;
      this.$emit("leave-team");
    },
    openPublicProfile() {
      let homeUrl = this.localizeConfig?.homeUrl;
      let windowReference = window.open();
      windowReference.location = `${homeUrl}/race/profile/${this.member.id}`;
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin defaultText {
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
}
@mixin textBold {
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
}
@mixin defaultAvatar($size) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  height: $size;
  width: $size;
  font-weight: bold;
}
.card-dialog {
  background: #ffffff;
  border-radius: 5px;
  padding: 25px 0 25px 25px;

  color: #000000;
  .dialog-item {
    font-size: 14px;
    font-weight: bold;
    line-height: 14px;
    text-transform: uppercase;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;

    cursor: pointer;
    outline: none;

    i {
      color: #c0c0c0;
      margin-right: 14px;
    }
  }
}
.lb-member-card {
  display: grid;
  grid-template-columns: 95px 1fr;
  background: #000000;
  width: 100%;
  padding: 23px 16px 22px 0;
  @media screen and (max-width: 499px) {
    grid-template-columns: 55px 1fr;
  }
  .user-position {
    @include textBold;
    text-align: center;
    padding-top: 8px;
  }
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .user-details {
      @include textBold;
      display: grid;
      grid-template-columns: 30px 1fr;
      column-gap: 20px;
      margin-bottom: 18px;
      position: relative;
      .captain-icon {
        position: absolute;
        top: -2px;
        left: 21px;
        height: 16px;
        width: 16px;
        border-radius: 100px;
        background-color: #cf1019;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        i {
          font-size: 12px;
          margin-left: 1px;
        }
      }
      .default-avatar {
        @include defaultAvatar(30px);
        font-weight: bold;
      }
      .user-avatar {
        img {
          @include defaultAvatar(30px);
        }
      }
      .user-name {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .more-button {
          i {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: #717171;
            height: 30px;
            width: 30px;
            border-radius: 100px;
            transition: all 200ms ease-in-out;
          }
          i:hover {
            background-color: #191919;
          }
        }

        .user-name-wrap {
          display: flex;
          flex-direction: column;

          .user-name-status {
            font-size: 9px;
            line-height: 11px;
            font-weight: 600;
            color: #cf1019;
          }
        }
      }

      &.has-spartan-plus {
        .default-avatar,
        .user-avatar img {
          border: 2px solid #cf1019;
        }
      }
    }
    .user-stats {
      @include defaultText;
      display: grid;
      grid-template-columns: repeat(4, minmax(auto, max-content));
      gap: 4px 32px;
      font-weight: 600;
      @media screen and (max-width: 499px) {
        gap: 4px 24px;
      }
      .stats-title {
        font-size: 10px;
        line-height: 12px;
        color: #9d9d9d;
        text-transform: uppercase;
        font-weight: 600;
      }
    }
  }
}
.colored-card {
  background: #121212;
}
.current-user {
  background-color: #cf102d;
  .card-body {
    .user-details {
      .captain-icon {
        background-color: #ffffff;
        i {
          color: #cf1019;
        }
      }
      .user-name {
        .more-button {
          i {
            color: #e06b82;
          }
          i:hover {
            background-color: #d42842;
          }
        }
        .user-name-wrap {
          .user-name-status {
            color: white;
          }
        }
      }

      &.has-spartan-plus {
        .user-avatar img,
        .default-avatar {
          border: none;
        }
      }
    }
    .user-stats {
      .stats-title {
        color: #ffffff;
        opacity: 0.7;
      }
    }
  }
}
</style>
