<template>
  <div class="team-view-wrapper">
    <transition :name="transitionName" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>
<script>
export default {
  name: "TeamViewWrapper",
  data() {
    return {
      transitionName: ""
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (to.name === "teamEvents")
          this.transitionName = "slide-x-reverse-transition";
        if (to.name === "teamLeaderboard")
          this.transitionName = "slide-x-transition";
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.team-view {
  max-width: 670px;
  width: 100%;
  .team-blocked {
    background-color: #121212;
    display: flex;
    flex-direction: column;
    padding: 50px 40px;
    min-height: 364px;
    @media screen and (max-width: 499px) {
      padding: 50px 64px;
      min-height: 518px;
    }
    .blocked-header {
      display: flex;
      @media screen and (max-width: 499px) {
        display: none;
      }
      .back-btn {
        display: flex;
        flex-direction: row;
        align-items: center;

        font-size: 14px;
        font-weight: bold;
        line-height: 14px;
        text-transform: uppercase;

        i {
          font-size: 16px;
          margin-right: 6px;
        }
      }
    }
    .blocked-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 66px;
      @media screen and (max-width: 499px) {
        margin-top: 144px;
      }
      i {
        color: #4f4f4f;
        font-size: 36px;
      }
      .blocked-text {
        max-width: 346px;
        width: 100%;
        text-align: center;
        margin-top: 8px;

        font-size: 14px;
        line-height: 21px;
      }
    }
  }
  .team-stats {
    margin: 64px 0 70px 0;
    .stats-title {
      font-size: 24px;
      font-weight: bold;
      line-height: 28px;
      text-transform: capitalize;
      margin-bottom: 32px;
    }
    .stats-list {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      .stats-item {
        display: flex;
        flex-direction: column;
        flex: 0 0 auto;
        padding-right: var(--stat-padding, 42px);
        .stat-title {
          margin-bottom: 6px;
          font-size: 12px;
          line-height: 15px;
          text-transform: capitalize;
          font-weight: 600;
          color: #757474;
        }
        .stat-value {
          font-size: 22px;
          line-height: 24px;
          font-weight: bold;
          text-transform: uppercase;
          .text {
            font-size: 11px;
            // text-transform: uppercase;
          }
        }
      }
      .stats-item:not(:first-child) {
        padding-left: var(--stat-padding, 42px);
      }
      .stats-item:not(:last-child) {
        border-right: 1px solid rgba(255, 255, 255, 0.2);
      }
    }
    .stats-list::-webkit-scrollbar {
      display: none;
    }
    @media screen and (max-width: 900px) {
      --stat-padding: 36px;
    }
    @media screen and (max-width: 499px) {
      --stat-padding: 38px;
      width: 100vw;
      padding-left: 24px;
      margin: 50px 0 30px 0;
    }
    @media screen and (max-width: 375px) {
      padding-left: 16px;
    }
  }
  .team-tabs {
    display: flex;
    height: 42px;
    background-color: #020000;
    flex-direction: row;
    width: 100%;
    z-index: 20;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    .tabs-list {
      display: flex;
      position: relative;
      .slider-wrapper {
        position: absolute;
        height: 3px;
        bottom: 0;
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
        .tab-slider {
          height: 100%;
          width: 100%;
          background-color: #ffffff;
        }
      }
    }
    .team-tab {
      font-size: 14px;
      color: #9d9d9d;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-weight: bold;
      margin: 0 16px;
      cursor: pointer;
      outline: none;
    }
    .active-tab {
      color: #ffffff;
    }
  }
}
</style>
