<template>
  <div class="sidebar-filters">
    <div class="filters-list">
      <div class="filter-name">{{ $t("sort_by") }}</div>
      <div class="sf-sort">
        <!-- results sort -->
        <div class="sort-types" v-if="filtersType !== 'teamLeaderboard'">
          <div
            @click="changeSort('completion_time')"
            class="sort-type cursor-pointer"
            :class="[sortBy === 'completion_time' ? 'active-filter' : '']"
          >
            <span>{{ $t("finishing_time") }}</span>
            <i
              class="material-icons"
              v-if="filterActive('completion_time', 'desc')"
              >arrow_downward</i
            >
            <i
              class="material-icons"
              v-if="filterActive('completion_time', 'asc')"
              >arrow_upward</i
            >
          </div>
          <div
            @click="changeSort('rank_age_group')"
            class="sort-type cursor-pointer"
            :class="[sortBy === 'rank_age_group' ? 'active-filter' : '']"
          >
            <span>{{ $t("age_place") }}</span>
            <i
              class="material-icons"
              v-if="filterActive('rank_age_group', 'desc')"
              >arrow_downward</i
            >
            <i
              class="material-icons"
              v-if="filterActive('rank_age_group', 'asc')"
              >arrow_upward</i
            >
          </div>
          <div
            @click="changeSort('rank_overall')"
            class="sort-type cursor-pointer"
            :class="[sortBy === 'rank_overall' ? 'active-filter' : '']"
          >
            <span>{{ $t("overall_place") }}</span>
            <i
              class="material-icons"
              v-if="filterActive('rank_overall', 'desc')"
              >arrow_downward</i
            >
            <i class="material-icons" v-if="filterActive('rank_overall', 'asc')"
              >arrow_upward</i
            >
          </div>
          <div
            @click="changeSort('rank_gender')"
            class="sort-type cursor-pointer"
            :class="[sortBy === 'rank_gender' ? 'active-filter' : '']"
          >
            <span>{{ $t("gender_place") }}</span>
            <i class="material-icons" v-if="filterActive('rank_gender', 'desc')"
              >arrow_downward</i
            >
            <i class="material-icons" v-if="filterActive('rank_gender', 'asc')"
              >arrow_upward</i
            >
          </div>
        </div>
        <!-- leaderboard sort -->
        <div class="sort-types" v-if="filtersType === 'teamLeaderboard'">
          <template v-for="(sortType, index) in leaderboardParams.sortTypes">
            <div
              @click="changeLeaderboardSort(sortType)"
              :key="index"
              class="sort-type cursor-pointer"
              :class="[sortBy === sortType ? 'active-filter' : '']"
            >
              <span v-if="sortType !== 'distance_km'">{{ $t(sortType) }}</span>
              <span v-if="sortType === 'distance_km'">{{
                $t("distance")
              }}</span>
              <i class="material-icons" v-if="filterActive(sortType, 'desc')"
                >arrow_downward</i
              >
              <i class="material-icons" v-if="filterActive(sortType, 'asc')"
                >arrow_upward</i
              >
            </div>
          </template>
        </div>
      </div>
      <div class="sf-divider"></div>
      <!-- leaderboard filters -->
      <template v-if="filtersType === 'teamLeaderboard'">
        <div class="filter-name">{{ $t("race_type") }}</div>
        <div class="sf-filters">
          <div class="sort-types">
            <template v-for="(category, index) in filterCategories">
              <div
                :key="index"
                class="sort-type cursor-pointer"
                @click="changeCategory(category.id)"
                :class="[
                  selectedCategory === category.id ? 'active-filter' : '',
                ]"
              >
                <div class="sort-text">{{ category.name }}</div>
              </div>
            </template>
          </div>
        </div>
        <div class="sf-divider"></div>
        <div class="filter-name">{{ $t("year") }}</div>
        <div class="sf-filters">
          <div class="sort-types">
            <template v-for="(year, index) in filterYears">
              <div
                :key="index"
                class="sort-type cursor-pointer"
                @click="changeYear(year)"
                :class="[selectedYear === year ? 'active-filter' : '']"
              >
                <div class="sort-text">{{ year }}</div>
              </div>
            </template>
          </div>
        </div>
        <div class="sf-divider"></div>
      </template>
    </div>
    <nav class="sf-footer">
      <v-btn
        large
        rounded
        depressed
        light
        text
        @click="clear()"
        class="mem-button-padding mem-btn-bold"
        >{{ $t("clear_all") }}</v-btn
      >
      <v-btn
        large
        rounded
        depressed
        dark
        @click="applyFilters()"
        color="#121212"
        class="mem-button-padding mem-btn-bold"
        >{{ $t("apply") }}</v-btn
      >
    </nav>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    sortBy: "",
    sortDirection: "",
    selectedCategory: "",
    selectedYear: "",
  }),
  // filters types: 'teamLeaderboard', 'resultsList'
  props: ["filtersType"],
  computed: {
    ...mapGetters([
      "sort",
      "leaderboardParams",
      "filterYears",
      "filterCategories",
    ]),
  },
  methods: {
    ...mapActions(["addSortParam", "clearFilters", "updateSortParams"]),
    filterActive(sort, direction) {
      // let { sortBy, sortDirection } = this.sort;
      return sort === this.sortBy && direction === this.sortDirection;
    },
    changeSort(type) {
      if (type !== this.sortBy) {
        this.sortDirection = "";
        this.sortBy = type;
      }

      if (!this.sortDirection) this.sortDirection = "asc";
      else if (this.sortDirection === "asc") this.sortDirection = "desc";
      else {
        this.sortBy = "";
        this.sortDirection = "";
      }

      // this.addSortParam({
      //   sortBy: type,
      //   sortDirection: this.sort.sortDirection
      // });
    },
    changeLeaderboardSort(type) {
      if (type !== this.sortBy) {
        this.sortBy = type;
        this.sortDirection = "desc";
      } else {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      }
    },
    changeCategory(id) {
      if (this.selectedCategory === id) this.selectedCategory = "";
      else this.selectedCategory = id;
    },
    changeYear(year) {
      if (this.selectedYear === year) this.selectedYear = "";
      else this.selectedYear = year;
    },
    clear() {
      // this.clearFilters()
      // this.$emit("close-sidebar");
      this.sortBy = "";
      this.sortDirection = "";
      this.selectedCategory = "";
      this.selectedYear = "";
    },
    async applyFilters() {
      if (this.filtersType === "resultsList")
        this.updateSortParams({
          sortBy: this.sortBy,
          sortDirection: this.sortDirection,
        });

      this.$emit("close-sidebar");
      this.$emit("apply-filters", {
        sortBy: this.sortBy,
        sortDirection: this.sortDirection,
        category: this.selectedCategory,
        year: this.selectedYear,
      });
    },
  },
  created() {
    document.getElementsByTagName('html')[0].style.overflowY = 'hidden'
  },
  destroyed() {
    document.getElementsByTagName('html')[0].removeAttribute("style");
  },
  mounted() {
    let { sortBy, sortDirection } = this.sort;

    if (this.filtersType === "teamLeaderboard") {
      sortBy = this.leaderboardParams.sort;
      sortDirection = this.leaderboardParams.direction;
      this.selectedCategory = this.leaderboardParams.category;
      this.selectedYear = this.leaderboardParams.year;
    }

    this.sortBy = sortBy;
    this.sortDirection = sortDirection;
  },
};
</script>
<style lang="scss" scoped>
.sidebar-filters {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .filters-list {
    padding: 22px 36px 0 36px;
    overflow-y: auto;
    height: 100%;

    // background: black;
    // sf - sidebar filters
    .sf-divider {
      height: 1px;
      background: rgba(0, 0, 0, 0.4);
    }
    .filter-name {
      padding-left: 26px;
      padding-top: 24px;
      font-weight: 600;
      font-size: 12px;
      line-height: 100%;
      /* identical to box height, or 12px */

      letter-spacing: 0.0025em;
      text-transform: uppercase;

      /* Grey 3 */

      color: #4f4f4f;
    }
    .sf-sort,
    .sf-filters {
      padding: 24px 0 29px 12px;
    }
    .sf-sort,
    .sf-filters {
      .sort-types {
        display: grid;
        grid-template-columns: 150px 150px;
        grid-row-gap: 8px;

        font-size: 14px;
        font-weight: 600;
        color: #000000;
        text-transform: capitalize;

        .sort-type {
          height: 30px;
          display: flex;
          align-items: center;
          padding-left: 14px;
          i {
            font-size: 16px;
            margin-left: 5px;
          }
        }

        .active-filter {
          background: #e0e0e0;
          // opacity: 0.16;
          border-radius: 15px;
        }
      }
    }
    .sf-filters {
      .sort-types {
        .sort-type {
          padding-left: 0;
          .sort-text {
            padding-left: 14px;
            padding-right: 14px;
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 100%;
          }
        }
        .active-filter {
          background: none;
          .sort-text {
            background: #e0e0e0;
            border-radius: 15px;
          }
        }
      }
    }
  }
  .sf-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.4);
    min-height: 84px;
    display: flex;
    // grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    // position: fixed;
    // width: 100%;
    // bottom: 0;
  }
}
@media screen and (max-width: 499px) {
  .sidebar-filters {
    .filters-list {
      padding: 22px 24px 0 24px;
    }
  }
}
@media screen and (max-width: 375px) {
  .sidebar-filters {
    .filters-list {
      padding: 15px 12px 0 5px;
      .sf-sort {
        .sort-types {
          grid-template-columns: 153px;
          grid-row-gap: 10px;
        }
      }
    }
  }
}
</style>