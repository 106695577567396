<template>
  <div class="schedule-dialog">
    <div class="dialog-header">
      <div class="dialog-title">{{ $t("schedule") }}</div>
      <i class="material-icons" @click="closeDialog"> close </i>
    </div>
    <div class="list-loading" v-if="isLoading">
      <v-progress-circular
        indeterminate
        color="#cacaca"
        :size="26"
      ></v-progress-circular>
    </div>
    <div class="list-wrap" v-if="!isLoading">
      <div class="schedule-list">
        <!-- members without start time -->
        <div v-if="blankTimeList.length !== 0">
          <template v-for="(group, key) in blankTimeMembers">
            <div :key="key" class="list-group">
              <div class="group-title">
                <i class="material-icons"> watch_later </i>
                <span class="group-name">{{ key }}</span>
              </div>
              <template v-for="(member, index) in group">
                <div :key="index" class="group-member">
                  <div class="member-avatar">
                    <img v-if="member.photo" :src="member.photo" alt="" />
                    <div v-if="!member.photo" class="no-photo">
                      <default-avatar
                        :size="28"
                        :fontSize="20"
                        :text="member.name"
                      >
                      </default-avatar>
                    </div>
                  </div>
                  <div class="member-name">{{ member.name }}</div>
                </div>
              </template>
            </div>
          </template>
        </div>

        <!-- members who have the start time -->
        <div v-if="filledTimeList.length !== 0">
          <template v-for="(group, key) in filledTimeMembers">
            <div :key="key" class="list-group">
              <div class="group-title">
                <i class="material-icons"> watch_later </i>
                <span>{{ convertGroupTitle(key) }}</span>
                <span style="margin: 0 5px">|</span>
                <span class="group-name">{{
                  cutRegChose(group[0].registration_choice_name)
                }}</span>
              </div>
              <template v-for="(member, index) in group">
                <div :key="index" class="group-member">
                  <div class="member-avatar">
                    <img v-if="member.photo" :src="member.photo" alt="" />
                    <div v-if="!member.photo" class="no-photo">
                      <default-avatar
                        :size="28"
                        :fontSize="20"
                        :text="member.name"
                      >
                      </default-avatar>
                    </div>
                  </div>
                  <div class="member-name">{{ member.name }}</div>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { groupBy, orderBy, map, filter, sortBy, lowerCase } from "lodash";
import { mapGetters, mapActions } from "vuex";
import DefaultAvatar from "../../../components/DefaultAvatar.vue";
export default {
  name: "EventScheduleDialog",
  components: {
    DefaultAvatar,
  },
  data: () => ({
    isLoading: true,
  }),
  props: {
    teamId: {
      type: String,
      default: "",
    },
    event: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters(["teamEventMembers"]),
    // members without start time
    blankTimeList() {
      return map(
        filter(this.teamEventMembers, (member) => {
          return !member.registration_choice_wave_start_time;
        }),
        (member) => {
          member.registration_choice_name = this.cutRegChose(
            member.registration_choice_name
          );
          return member;
        }
      );
    },
    blankTimeMembers() {
      let orderRule = {
        elite: 1,
        "age group": 2,
        morning: 3,
        afternoon: 4,
        festival: 5,
        parking: 6,
      };

      return groupBy(
        sortBy(this.blankTimeList, (member) => {
          return orderRule[lowerCase(member.registration_choice_name)];
        }),
        "registration_choice_name"
      );
    },
    // members who have the start time
    filledTimeList() {
      return map(
        filter(this.teamEventMembers, "registration_choice_wave_start_time"),
        (member) => {
          member.registration_choice_wave_start_time = moment(
            member.registration_choice_wave_start_time,
            "hh:mm a"
          ).format("HH:mm");
          return member;
        }
      );
    },
    filledTimeMembers() {
      return groupBy(
        orderBy(
          this.filledTimeList,
          "registration_choice_wave_start_time",
          "asc"
        ),
        "registration_choice_wave_start_time"
      );
    },
  },
  methods: {
    ...mapActions(["getTeamEventMembers"]),
    cutRegChose(text) {
      if (!text) return;
      let str;
      if (text.indexOf("(") !== -1) str = text.substr(0, text.indexOf("("));
      else str = text;
      return str.trim().toLowerCase();
    },
    convertGroupTitle(title) {
      if (!title || title === "null") return "";
      return moment(title, "HH:mm").format("h:mm a");
    },
    closeDialog() {
      this.$emit("close-schedule");
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getTeamEventMembers({
      teamId: this.teamId,
      eventId: this.event.id,
    });
    this.isLoading = false;
  },
};
</script>
<style lang="scss" scoped>
.schedule-dialog {
  background-color: #ffffff;
  max-height: 558px;
  color: #000000;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  .dialog-header {
    position: sticky;
    top: 0;
    background: #ffffff;
    padding: 30px 16px 10px 24px;
    border-radius: 6px 6px 0 0;
    .dialog-title {
      font-size: 18px;
      line-height: 28px;
      font-weight: bold;
    }
    i {
      position: absolute;
      top: 12px;
      right: 12px;
      color: #757575;
      cursor: pointer;
      outline: none;
    }
  }
  .list-loading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 100%;
  }
  .list-wrap {
    overflow: hidden;
    overflow-y: scroll;
    .schedule-list {
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 24px;
      .list-group {
        .group-title {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.035em;
          margin-top: 23px;
          margin-bottom: 16px;
          .group-name {
            text-transform: capitalize;
          }
          i {
            color: #cacaca;
            font-size: 20px;
            margin-right: 10px;
          }
        }
        .group-member {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 56px;
          .member-avatar {
            display: flex;
            img {
              height: 28px;
              width: 28px;
              border-radius: 100px;
            }
            img,
            .no-photo {
              margin-right: 12px;
            }
          }
          .member-name {
            font-size: 14px;
            line-height: 17px;
            padding: 19px 0 19px 0;
            border-bottom: 1px solid #dddddd;
            width: 100%;
          }
        }
      }
    }
  }
}
</style>