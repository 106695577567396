<template>
  <div class="page page--team-edit" v-if="!loading">
    <div
      class="back-button"
      @click="
        $router.push({ name: 'teamLeaderboard', params: { id: team.slug } })
      "
    >
      <i class="material-icons">keyboard_arrow_left</i>
      <span>{{ $t("back") }}</span>
    </div>
    <div class="mem-font--page-view__title list-title">
      {{ $t("team_settings") }}
    </div>

    <v-form ref="form" class="edit-form" lazy-validation @submit.prevent="">
      <div class="card team-edit-form">
        <div class="card__body">
          <span class="card__subtitle">
            {{ $t("choose_privacy") }}
          </span>
          <span class="card__description">
            {{ $t("choose_privacy_description") }}
          </span>

          <div class="team-types">
            <div
              v-for="(type, index) in types"
              :class="[
                `team-type`,
                form.type === type.slug ? 'team-type--active' : '',
              ]"
              :key="index"
              @click="form.type = type.slug"
            >
              <v-icon class="type__icon">{{ type.icon }}</v-icon>
              <span class="type__name">{{ $t(type.name) }}</span>
            </div>
          </div>

          <base-input
            v-model.trim="form.name"
            :label="$t('team_name')"
            class="input-field"
            :rules="rules.name"
            :error="!!errors.name"
            :error-messages="errors.name"
            counter="70"
            validate-on-blur
            @focus="onInputFocus('name')"
          />
          <base-address-search
            v-model="form.city"
            :label="$t('team_city')"
            class="input-field"
            geocode-type="city"
            :rules="rules.city"
            :error="!!errors.city"
            :error-messages="errors.city"
            validate-on-blur
            @on-place-change="setTeamCoords"
            @focus="onInputFocus('city')"
          />
          <base-textarea
            class="input-field"
            v-model.trim="form.description"
            :label="$t('description')"
            :rules="rules.description"
            :error="!!errors.description"
            :error-messages="errors.description"
            validate-on-blur
            counter="200"
            auto-grow
            :rows="1"
            @focus="onInputFocus('description')"
          >
          </base-textarea>
        </div>
      </div>
      <div class="card social">
        <div class="card__title">
          {{ $t("social") }}
        </div>
        <div class="card__body">
          <v-text-field
            v-model.trim="form.links.instagram"
            type="url"
            :label="$t('instagram_link')"
            :rules="rules.instagram"
            :error="!!errors.instagram"
            :error-messages="errors.instagram"
            validate-on-blur
            @focus="onInputFocus('instagram')"
          >
            <template v-slot:prepend-inner>
              <img :src="require('@/assets/instagram-icon.svg')" />
            </template>
            <template v-slot:append v-if="!!form.links.instagram">
              <v-icon class="clear-field" @click="clearField('instagram')">
                remove_circle
              </v-icon>
            </template>
          </v-text-field>
          <v-text-field
            v-model.trim="form.links.facebook"
            :label="$t('facebook_link')"
            :rules="rules.facebook"
            :error="!!errors.facebook"
            :error-messages="errors.facebook"
            validate-on-blur
            @focus="onInputFocus('facebook')"
          >
            <template v-slot:prepend-inner>
              <img :src="require('@/assets/facebook-icon.svg')" />
            </template>
            <template v-slot:append v-if="!!form.links.facebook">
              <v-icon class="clear-field" @click="clearField('facebook')">
                remove_circle
              </v-icon>
            </template>
          </v-text-field>
        </div>
      </div>
      <div class="buttons">
        <v-btn
          class="button-submit button-delete"
          v-if="showDelete"
          :loading="inlineLoading"
          depressed
          rounded
          large
          outlined
          @click="showConfirmDelete = true"
        >
          {{ $t("delete_team") }}
        </v-btn>
        <v-btn
          class="button-submit button-cancel"
          v-if="!isNotChanged"
          :loading="loading"
          depressed
          rounded
          large
          outlined
          @click="cancel"
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          class="button-submit"
          :class="{ disabled: buttonDisabled || isNotChanged }"
          v-if="!isNotChanged"
          :loading="inlineLoading"
          color="#FFFFFF"
          depressed
          rounded
          large
          dark
          @click="saveTeam"
        >
          <span class="action__desktop">{{ $t("save") }}</span>
          <span class="action__mobile">{{ $t("save") }}</span>
        </v-btn>
      </div>
    </v-form>

    <v-dialog
      v-model="showConfirmDelete"
      overlay-color="#000000"
      overlay-opacity="0.54"
      :max-width="410"
      light
      class="mem-dialog"
    >
      <v-card class="delete-confirm">
        <v-icon
          class="close-icon"
          :size="30"
          color="black"
          @click="showConfirmDelete = false"
        >
          close
        </v-icon>
        <v-icon class="confirm-icon" :size="58" color="#DCDCDC">
          delete_outline
        </v-icon>
        <v-card-title> {{ $t("delete_team_quest") }}? </v-card-title>

        <v-card-text>
          {{ $t("delete_team_quest_description") }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <base-button
            class="action-button"
            btn-type="tertiary-light"
            @click="showConfirmDelete = false"
          >
            {{ $t("cancel") }}
          </base-button>

          <base-button
            class="action-button"
            btn-type="secondary-light"
            @click="onDeleteTeam"
          >
            {{ $t("delete") }}
          </base-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { cloneDeep, isEqual, isEmpty } from "lodash";
import BaseTextarea from "@/components/base/BaseTextarea.vue";
import {
  censorValidator,
  domainValidator,
  requireValidator,
} from "@/common/validators";
import BaseInput from "@/components/base/BaseInput.vue";
import BaseAddressSearch from "@/components/base/BaseAddressSearch.vue";
import { mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/base/BaseButton";

export default {
  name: "EditTeamView",
  components: { BaseButton, BaseAddressSearch, BaseInput, BaseTextarea },
  data() {
    return {
      loading: false,
      inlineLoading: false,
      showConfirmDelete: false,
      types: [
        {
          id: 0,
          name: "team_type_public",
          slug: "public",
          icon: "public",
          isActive: true,
        },
        {
          id: 1,
          name: "team_type_privacy",
          slug: "privacy",
          icon: "lock",
          isActive: false,
        },
      ],
      form: {
        type: "public",
        name: "",
        city: "",
        description: "",
        links: {
          instagram: "",
          facebook: "",
        },
      },
      teamCoords: {},
      originForm: null,
      slug: "",
      errors: {},
      rules: {
        name: [
          (val) => requireValidator(val) || this.$t("field_required"),
          (val) => censorValidator(val) || this.$t("error_team_abuse_word"),
        ],
        city: [
          (val) => censorValidator(val) || this.$t("error_team_abuse_word"),
        ],
        description: [
          (val) => censorValidator(val) || this.$t("error_team_abuse_word"),
        ],
        instagram: [
          (val) =>
            domainValidator(val, "instagram.com") ||
            this.$t("instagram_link_error"),
        ],
        facebook: [
          (val) =>
            domainValidator(val, "facebook.com") ||
            this.$t("facebook_link_error"),
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["team", "userData"]),
    buttonDisabled() {
      return !this.form.name;
    },
    role() {
      return this.team?.role || "guest";
    },
    isNotChanged() {
      return isEqual(this.form, this.originForm);
    },
    showDelete() {
      return (
        this.isNotChanged &&
        this.team?.members_count < 30 &&
        this.team?.role === "owner"
      );
    },
  },
  methods: {
    ...mapActions([
      "getTeam",
      "updateTeam",
      "deleteTeam",
      "handleToolbarNotification",
    ]),
    async saveTeam() {
      this.errors = {};
      this.inlineLoading = true;
      const { form } = this.$refs;
      if (form?.validate() && !this.isNotChanged) {
        const payload = this.prepareForm();
        try {
          const result = await this.updateTeam({ teamId: this.slug, payload });
          if (result?.name) {
            this.handleToolbarNotification({
              type: "success",
              text: "notification_team_is_updated",
              isVisible: true,
            });
            setTimeout(() => {
              this.handleToolbarNotification({ isVisible: false });
            }, 3000);
          }
        } catch (e) {
          if (e.status === 422 && e.data?.errors) {
            const errors = Object.entries(e.data.errors);
            errors.forEach(([key, value]) => {
              const [msg] = value;
              this.$set(this.errors, key, this.$t(`error_team_${msg}`));
            });
          } else {
            console.error(e);
          }
        }
      }
      this.inlineLoading = false;
    },
    setTeamCoords(place) {
      let { geometry } = place || {};

      this.teamCoords.locationGeoLat = geometry?.lat || null;
      this.teamCoords.locationGeoLon = geometry?.lng || null;
    },
    async onDeleteTeam() {
      this.errors = {};
      this.inlineLoading = true;
      if (this.isNotChanged) {
        try {
          await this.deleteTeam(this.team.slug);
        } catch (e) {
          console.error(e);
        }
        await this.$router.push("/teams");
      }
      this.inlineLoading = false;
    },
    cancel() {
      this.form = cloneDeep(this.originForm);
    },
    prepareForm() {
      return {
        name: this.form.name,
        description: this.form.description,
        isPublic: this.form.type === "public",
        location: this.form.city,
        instagramLink: this.form.links.instagram,
        facebookLink: this.form.links.facebook,
        locationGeoLat: this.teamCoords.locationGeoLat || null,
        locationGeoLon: this.teamCoords.locationGeoLon || null,
      };
    },
    clearField(name) {
      if (this.form.links[name]) {
        this.form.links[name] = "";
      }
    },
    onInputFocus(field) {
      // console.log("focus", field);
      this.$delete(this.errors, field);
    },
  },
  watch: {
    team: {
      immediate: true,
      deep: true,
      async handler() {
        this.slug = this.$route.params?.id;
        this.loading = !this.team?.name;
        if (isEmpty(this.team)) {
          await this.getTeam(this.slug);
        }
        if (!["owner", "captain"].includes(this.role)) {
          await this.$router.push({
            name: "teamLeaderboard",
            params: { id: this.$route.params?.id },
          });
        }
        // parse data
        this.form = {
          type: this.team?.is_public ? "public" : "privacy",
          name: this.team?.name,
          city: this.team?.location,
          description: this.team?.description,
          links: {
            instagram: this.team?.instagram_link,
            facebook: this.team?.facebook_link,
          },
        };
        // if (this.team?.location_geo_lat && this.team?.location_geo_lon) {
        //   this.form.city.data = {
        //     lat: this.team?.location_geo_lat,
        //     lng: this.team?.location_geo_lon,
        //   };
        // }
        this.originForm = cloneDeep(this.form);
        this.loading = false;
      },
    },
  },
  beforeDestroy() {
    this.handleToolbarNotification({ isVisible: false });
  },
};
</script>
<style lang="scss" scoped>
@import "./src/sass/variables";
.page--team-edit {
  padding-bottom: 180px;
  .back-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 44px;
    margin-bottom: 24px;

    position: relative;
    font-weight: bold;
    font-size: 14px;
    @include cursorPointer;

    // margin-bottom: 40px;
    span {
      line-height: 14px;
      margin-left: 20px;
    }

    text-transform: uppercase;

    i {
      position: absolute;
      left: -8px;
    }
  }

  .list-title {
    margin: 30px 0 36px 0;
  }

  .card {
    display: flex;
    flex-direction: column;
    max-width: min(670px, 100%);
    width: 100%;
    background: #121212;
    border-radius: 5px;
    padding: 36px 32px 24px;
    align-items: center;
    margin: 0;

    & + .card {
      margin-top: 8px;
    }

    &__body {
      max-width: 413px;
      width: 100%;
      padding: 28px 0 40px 0;
    }

    ::v-deep(.v-label) {
      text-transform: none;
    }
  }

  .team-edit-form {
    .card__subtitle {
      display: block;
      font-size: 14px;
      color: $text-grey;
      margin-bottom: 13px;
      font-weight: 600;
    }

    .card__description {
      display: inline-block;
      font-size: 12px;
      line-height: 14px;
      color: $text-grey;
      margin-bottom: 24px;
    }

    .team-types {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 7px;
      margin-bottom: 52px;

      .team-type {
        display: flex;
        align-items: center;
        border: 1px solid #717171;
        border-radius: 8px;
        padding: 15px;
        cursor: pointer;

        .type__icon {
          margin-right: 10px;
          color: $text-grey;
        }

        .type__name {
          font-size: 14px;
          font-weight: 700;
        }

        &--active {
          border: 1px solid #ffffff;

          .type__icon {
            color: #ffffff;
          }

          .type__name {
            color: #ffffff;
          }
        }
      }
    }

    .input-field {
      ::v-deep .v-label {
        top: -5px;

        &.v-label--active {
          top: 0;
        }
      }
      &.input-error {
        .v-label {
          color: #cf1019;
          text-align: center;
          height: 20px;
          font-weight: bold;
        }
      }

      &.base-textarea {
        ::v-deep .v-label {
          top: 2px;

          &.v-label--active {
            top: 7px;
          }
        }
      }

      & + .input-field {
        margin-top: 10px;
      }
    }
  }

  .social {
    .card__title {
      width: 100%;
      font-size: 24px;
      font-weight: 700;
      padding-left: 2px;
    }

    .card__body {
      padding-bottom: 20px;
      padding-top: 42px;
    }

    .v-input {
      ::v-deep .v-input__prepend-inner {
        margin-top: -2px;
        padding-right: 10px;
      }
      ::v-deep .v-input__append-inner {
        margin-top: -2px;
        padding-right: 10px;

        .clear-field {
          color: #606060;

          &:hover {
            color: white;
            cursor: pointer;
          }
        }
      }
      ::v-deep .v-label {
        top: -5px;

        &.v-label--active {
          top: 0;
        }
      }
    }
  }

  .buttons {
    max-width: min(670px, 100%);
    width: 100%;
    display: flex;
    justify-content: space-between;

    .button-submit {
      display: flex;
      flex: 1 0;
      max-width: 202px;
      color: #020000;
      margin-top: 27px;
      margin-left: auto;
      &.disabled {
        background-color: rgba(255, 255, 255, 0.2) !important;
      }

      &.button-delete,
      &.button-cancel {
        color: white;
      }

      &.button-cancel + .button-submit {
        margin-left: 14px;
      }
      &.button-delete {
        margin-left: 0;
      }
      .action__mobile {
        display: none;
      }
    }
  }
}

.delete-confirm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  position: relative;
  padding: 45px 50px;
  border-radius: 10px !important;

  .close-icon {
    position: absolute;
    top: 9px;
    right: 9px;

    &:hover,
    &:focus {
      &:after {
        opacity: 0;
      }
    }
  }

  ::v-deep(.v-card__title) {
    margin-top: 33px;
    padding: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #000000;
  }
  ::v-deep(.v-card__text) {
    margin-top: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 0;
    text-align: center;
    color: #aaaaaa;
  }
  ::v-deep(.v-card__actions) {
    margin-top: 27px;
    margin-bottom: 5px;
    padding-top: 0;
    padding-bottom: 0;

    .action-button {
      height: 36px;
      width: 139px;
      font-size: 12px;
    }

    .action-button + .action-button {
      margin-left: 12px;
    }
  }
}
@media screen and (max-width: 499px) {
  .page--team-edit {
    padding-bottom: 90px;
    .back-button {
      font-size: 16px;
      margin-left: 16px;
      margin-top: 30px;
      margin-bottom: 0;
    }

    .list-title {
      margin-left: 16px;
      margin-top: 16px;
    }

    .card {
      padding: 0 24px 24px;

      &__body {
        padding-bottom: 0;
      }
    }

    .team-edit-form {
      .team-types {
        gap: 16px;
        margin-bottom: 24px;

        .team-type {
          padding: 13px;
        }
      }

      .card__description {
        max-width: 80%;
        margin-bottom: 28px;
      }

      .input-field {
        & + .input-field {
          margin-top: 10px;
        }
      }
    }

    .social {
      padding-top: 30px;

      .card__title {
        width: 100%;
        font-size: 16px;
        font-weight: bold;
        padding-left: 0;
      }

      .card {
        padding-top: 14px;
        padding-bottom: 0;

        &__body {
          padding-top: 21px;
          padding-bottom: 0;
        }
      }
    }

    .buttons {
      padding-left: 16px;
      padding-right: 16px;
      .button-submit {
        margin-top: 12px;
        .action__desktop {
          display: none;
        }

        .action__mobile {
          display: inline-block;
        }

        &.button-cancel {
          margin-left: 0;
        }
      }
    }
  }
}
</style>
